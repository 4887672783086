import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';
import { AlertService } from './util/alert.service';

import { environment } from '../../environments/environment';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalCaptchaComponent } from '../components/modal-captcha/modal-captcha.component';
import { UsuarioService } from './usuario.service';

let base_url = '';
let base_url_input = '';
let base_url_captcha = environment.base_url + '/usuario';

@Injectable({
  providedIn: 'root',
})
export class DataEmpresaService {
  tokenProvisional: string = '';

  _modalOptionsNormal: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'md',
    centered: true,
  };

  countCaptchaModal: number = 0;
  countRegistraModal: number = 0;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private _alertService: AlertService,
    private _usuarioService: UsuarioService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.validateUserPublic();
  }

  validateUserPublic() {
    if (this.authService.token && !this._usuarioService.isFree()) {
      base_url = environment.base_url + '/usuario/data';
      base_url_input = environment.base_url + '/usuario';
    } else {
      base_url = environment.base_url + '/public/data';
      base_url_input = environment.base_url + '/public';
    }
  }

  getHeadersForFilter() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    return this.http
      .get(`${base_url}/empresa/header`, { headers: headers })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getEmpresasByFiltros(filtros: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });
    let params = new HttpParams();

    // FILTROS DE BÙSQUEDA
    if (filtros.ruc) {
      params = params.append('ruc', filtros.ruc);
    } else {
      if (filtros.region) {
        params = params.append('region', filtros.region);
      }
      if (filtros.sectorEconomico) {
        params = params.append('sectorEconomico', filtros.sectorEconomico);
      }
      if (filtros.nombreEmpresa) {
        params = params.append('nombreEmpresa', filtros.nombreEmpresa);
      }
      if (filtros.agno) {
        params = params.append('agno', filtros.agno);
      }

      if (filtros['NombreEmpresa']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'NombreEmpresa', order: filtros['NombreEmpresa'] },
              ])
            )
          )
        );
      }

      if (filtros['indinfamb_agno']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinfamb_agno', order: filtros['indinfamb_agno'] },
              ])
            )
          )
        );
      }
      if (filtros['indinfamb_acc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinfamb_acc', order: filtros['indinfamb_acc'] },
              ])
            )
          )
        );
      }

      if (filtros['indinflab_agno']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinflab_agno', order: filtros['indinflab_agno'] },
              ])
            )
          )
        );
      }
      if (filtros['indinflab_acc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinflab_acc', order: filtros['indinflab_acc'] },
              ])
            )
          )
        );
      }

      if (filtros['IndInfAmb']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfAmb', order: filtros['IndInfAmb'] },
              ])
            )
          )
        );
      }
      if (filtros['IndInfAmbAcc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfAmbAcc', order: filtros['IndInfAmbAcc'] },
              ])
            )
          )
        );
      }

      if (filtros['IndInfLab']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfLab', order: filtros['IndInfLab'] },
              ])
            )
          )
        );
      }
      if (filtros['IndInfLabAcc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfLabAcc', order: filtros['IndInfLabAcc'] },
              ])
            )
          )
        );
      }

      if (filtros['IndInfAmbSemaforo']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'IndInfAmbSemaforo',
                  order: filtros['IndInfAmbSemaforo'],
                },
              ])
            )
          )
        );
      }
      if (filtros['IndInfLabSemaforo']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'IndInfLabSemaforo',
                  order: filtros['IndInfLabSemaforo'],
                },
              ])
            )
          )
        );
      }

      if (filtros['multas_laborales_judicial']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'multas_laborales_judicial',
                  order: filtros['multas_laborales_judicial'],
                },
              ])
            )
          )
        );
      }

      if (filtros['derramesacc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'derramesacc', order: filtros['derramesacc'] },
              ])
            )
          )
        );
      }
      if (filtros['accidentesacc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'accidentesacc', order: filtros['accidentesacc'] },
              ])
            )
          )
        );
      }
      if (filtros['nroproyectosacc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'nroproyectosacc',
                  order: filtros['nroproyectosacc'],
                },
              ])
            )
          )
        );
      }
      if (filtros['permisosaguaacc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'permisosaguaacc',
                  order: filtros['permisosaguaacc'],
                },
              ])
            )
          )
        );
      }
    }

    // FILTRO PARA PAGINACIÒN
    if (filtros.page) {
      params = params.append('page', filtros.page);
    }
    if (filtros.size) {
      params = params.append('size', filtros.size);
    }

    // console.log('params',params);

    return this.http
      .get(`${base_url}/empresa`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getEmpresasInput(search: string, size: number) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('nombreEmpresa', search);
    params = params.append('size', size.toString());

    return this.http
      .get(`${base_url_input}/nombre/empresa`, {
        headers: headers,
        params: params,
      })
      .pipe(
        map((resp: any) => {
          return resp.result;
        })
      );
  }

  getEmpresasByFiltrosDescargar(filtros: any) {
    let httpOption: any = {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.token,
      }),
      params: '',
      responseType: 'arraybuffer',
    };
    let params = new HttpParams();

    // FILTROS DE BÙSQUEDA
    if (filtros.ruc) {
      params = params.append('ruc', filtros.ruc);
    } else {
      if (filtros.region) {
        params = params.append('region', filtros.region);
      }
      if (filtros.sectorEconomico) {
        params = params.append('sectorEconomico', filtros.sectorEconomico);
      }
      if (filtros.nombreEmpresa) {
        params = params.append('nombreEmpresa', filtros.nombreEmpresa);
      }
      if (filtros.agno) {
        params = params.append('agno', filtros.agno);
      }

      if (filtros['NombreEmpresa']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'NombreEmpresa', order: filtros['NombreEmpresa'] },
              ])
            )
          )
        );
      }

      if (filtros['indinfamb_agno']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinfamb_agno', order: filtros['indinfamb_agno'] },
              ])
            )
          )
        );
      }
      if (filtros['indinfamb_acc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinfamb_acc', order: filtros['indinfamb_acc'] },
              ])
            )
          )
        );
      }

      if (filtros['indinflab_agno']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinflab_agno', order: filtros['indinflab_agno'] },
              ])
            )
          )
        );
      }
      if (filtros['indinflab_acc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'indinflab_acc', order: filtros['indinflab_acc'] },
              ])
            )
          )
        );
      }

      if (filtros['IndInfAmb']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfAmb', order: filtros['IndInfAmb'] },
              ])
            )
          )
        );
      }
      if (filtros['IndInfAmbAcc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfAmbAcc', order: filtros['IndInfAmbAcc'] },
              ])
            )
          )
        );
      }

      if (filtros['IndInfLab']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfLab', order: filtros['IndInfLab'] },
              ])
            )
          )
        );
      }
      if (filtros['IndInfLabAcc']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                { colname: 'IndInfLabAcc', order: filtros['IndInfLabAcc'] },
              ])
            )
          )
        );
      }

      if (filtros['IndInfAmbSemaforo']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'IndInfAmbSemaforo',
                  order: filtros['IndInfAmbSemaforo'],
                },
              ])
            )
          )
        );
      }
      if (filtros['IndInfLabSemaforo']) {
        params = params.append(
          'sort',
          encodeURI(
            btoa(
              JSON.stringify([
                {
                  colname: 'IndInfLabSemaforo',
                  order: filtros['IndInfLabSemaforo'],
                },
              ])
            )
          )
        );
      }
    }

    // FILTRO PARA PAGINACIÒN
    if (filtros.page) {
      params = params.append('page', filtros.page);
    }
    if (filtros.size) {
      params = params.append('size', filtros.size);
    }

    // console.log('params',params);
    httpOption.params = params;

    return this.http.get(`${base_url}/empresa/descargar`, httpOption).pipe(
      // return this.http.get(`${ base_url }/empresa/descargar`,{ headers: headers, params:params }).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
          }
        }
      )
      // catchError(err => {
      //   // console.log("ERROR", err);
      //   //Return an empty Observable which gets collapsed in the output
      //   return of(null);
      // })
    );
  }

  getEmpresasByRuc(ruc: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });
    let params = new HttpParams();

    // FILTROS DE BÙSQUEDA
    if (ruc) {
      params = params.append('ruc', ruc);
    }

    return this.http
      .get(`${base_url}/empresa`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            if (resp.empresas.length > 0) {
              return resp;
            } else {
              this._alertService
                .alertError(
                  'El RUC no fue encontrado',
                  'Intente buscar en nuestro listado de empresas',
                  'Volver a lista de empresas'
                )
                .then((resp) => {
                  if (resp.value) {
                    this.router.navigate([`/industrias-extractivas`]);
                  }
                });
            }
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getInfoForMap(filtros: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });
    let params = new HttpParams();

    if (filtros) {
      if (filtros.ruc) {
        params = params.append('ruc', filtros.ruc);
      }
      if (filtros.region) {
        params = params.append('region', filtros.region);
      }
      if (filtros.sectorEconomico) {
        params = params.append('sectorEconomico', filtros.sectorEconomico);
      }
      if (filtros.nombreEmpresa) {
        params = params.append('nombreEmpresa', filtros.nombreEmpresa);
      }
      if (filtros.agno) {
        params = params.append('agno', filtros.agno);
      }
      if (filtros.tipoInfraccion) {
        params = params.append('tipoInfraccion', filtros.tipoInfraccion);
      }
    }

    return this.http
      .get(`${base_url}/empresa/mapa`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getInfoAdicionalForMap(filtros: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });
    let params = new HttpParams();

    if (filtros) {
      if (filtros.ruc) {
        params = params.append('ruc', filtros.ruc);
      }
      if (filtros.region) {
        params = params.append('region', filtros.region);
      }
      if (filtros.sectorEconomico) {
        params = params.append('sectorEconomico', filtros.sectorEconomico);
      }
      if (filtros.nombreEmpresa) {
        params = params.append('nombreEmpresa', filtros.nombreEmpresa);
      }
      if (filtros.agno) {
        params = params.append('agno', filtros.agno);
      }
      if (filtros.tipoInfraccion) {
        params = params.append('tipo', filtros.tipoInfraccion);
      }
    }

    // console.log('params',params);
    return this.http
      .get(`${base_url}/empresa/mapa/informacion`, {
        headers: headers,
        params: params,
      })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  getInfoForMapByRuc(ruc: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('ruc', ruc);

    return this.http
      .get(`${base_url}/empresa/mapa`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getInfoForMapById(infraccionid) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('infraccionid', infraccionid);

    return this.http
      .get(`${base_url}/empresa/mapa/detalle`, {
        headers: headers,
        params: params,
      })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getInfoAdicionalForMapById(infraccionid) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('informacionid', infraccionid);

    return this.http
      .get(`${base_url}/empresa/mapa/informacion/detalle`, {
        headers: headers,
        params: params,
      })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getDetalleEmpresaByRuc(ruc: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('ruc', ruc);

    return this.http
      .get(`${base_url}/empresa/detalle`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error, 'industrias-extractivas');
            }
          }
        ),
        catchError((err) => {
          // console.log("ERROR", err);
          //Return an empty Observable which gets collapsed in the output
          return of(null);
        })
      );
  }

  getDetalleEmpresaByRucFromTabla(ruc: string, tabla: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('ruc', ruc);
    params = params.append('tabla', tabla);

    return this.http
      .get(`${base_url}/empresa/tablas`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error, 'industrias-extractivas');
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getDetalleEmpresaByRucFromTablaPaginated(
    ruc: string,
    tabla: string,
    page: number,
    size: number
  ) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let params = new HttpParams();
    params = params.append('ruc', ruc);
    params = params.append('tabla', tabla);
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());

    return this.http
      .get(`${base_url}/empresa/tablas`, { headers: headers, params: params })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error, 'industrias-extractivas');
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getDetalleEmpresaByRucFromTablaDescargar(ruc: string, tabla: string) {
    let httpOption: any = {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.token,
      }),
      params: '',
      responseType: 'arraybuffer',
    };

    let params = new HttpParams();
    params = params.append('ruc', ruc);
    params = params.append('tabla', tabla);

    httpOption.params = params;

    return this.http
      .get(`${base_url}/empresa/tablas/descargar`, httpOption)
      .pipe(
        // return this.http.get(`${ base_url }/empresa/tablas/descargar`,{ headers: headers, params:params }).pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error, 'industrias-extractivas');
            }
          }
        )
        // catchError(err => {
        //   // console.log("ERROR", err);
        //   //Return an empty Observable which gets collapsed in the output
        //   return of(null);
        // })
      );
  }

  getCruceEmpresa() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let body = {
      rucs: ['20100137390', '20323664227'],
    };

    return this.http
      .post(`${base_url}/empresa/cruce`, body, { headers: headers })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  getCruceEmpresaWithFile(archivo: File, filtros: any) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let formData = new FormData();
    formData.append('rucs', archivo);
    formData.append('filtros', filtros);

    // let body = {
    //   "rucs": ["20100137390", "20323664227"]
    // }

    return this.http
      .post(`${base_url}/empresa/cruce/archivo`, formData, { headers: headers })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  getCruceEmpresaWithFileOld(archivo: File) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      let xhr = new XMLHttpRequest();

      formData.append('rucs', archivo, archivo.name);

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          if (xhr.status === 200 || xhr.status === 201) {
            // console.log('Excel subido');
            resolve(JSON.parse(xhr.response));
          } else {
            // console.log('Fallo al subir el archivo');
            reject(JSON.parse(xhr.response));
          }
        }
      };

      let url = base_url + '/empresa/cruce/archivo';

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.authService.token);
      xhr.send(formData);
    });
  }

  getCruceEmpresaDownloadFile(rucs: any, filtros: any) {
    // console.log('rucs',rucs);

    // const headers = new HttpHeaders({
    //   'Authorization': 'Bearer ' + this.authService.token
    // });

    const httpOption: Object = {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.token,
      }),
      responseType: 'arraybuffer',
    };

    let body = { rucs: rucs, filtros: filtros };

    return this.http
      .post(`${base_url}/empresa/cruce/descargar`, body, httpOption)
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  getCruceEmpresaDownloadFilePDF(rucs: any, filtros: any) {
    const httpOption: Object = {
      observe: 'response',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.authService.token,
      }),
      responseType: 'arraybuffer',
    };

    let body = { rucs: rucs, filtros: filtros };

    console.log('body', body);

    return this.http
      .post(`${base_url}/empresa/cruce/descargar/pdf`, body, httpOption)
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  getRepresentantesEmpresaByRuc(ruc: string) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    let body = {
      rucs: [ruc],
    };

    return this.http
      .post(`${base_url}/empresa/representantes`, body, { headers: headers })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  limiteCaptcha(recaptcha) {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    return this.http
      .post(
        `${base_url_captcha}/limite/captcha`,
        { recaptcha: recaptcha },
        { headers: headers }
      )
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        )
      );
  }

  getNewFeautures() {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authService.token,
    });

    return this.http
      .get(`${base_url}/empresa/indicadores-estado`, { headers: headers })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            if (error instanceof HttpErrorResponse) {
              this.manejarError(error);
            }
          }
        ),
        catchError((err) => {
          // console.log("ERROR", err);
          //Return an empty Observable which gets collapsed in the output
          return of(null);
        })
      );
  }

  manejarError(error: HttpErrorResponse, urlRedirect: string = '') {
    // console.log('Error',error);

    let titulo = '';
    let subtitulo = '';

    switch (error.status) {
      case 400:
        if (error.error.code === 'MEMBRESHIP_NOT_ALLOWED') {
          this._alertService
            .alertInfo(
              'Descubre más funcionalidades',
              'Por ahora no puedes acceder a esta opción. Mira nuestros planes para utilizarla y genera más conocimiento.',
              'Ver planes'
            )
            .then((resp) => {
              if (resp.value) {
                this.router.navigate(['/planes']);
              }
            });
        } else {
          this._alertService
            .alertError('Ups', 'Ocurrió un problema. Vuelve a intentar.')
            .then((resp) => {
              if (urlRedirect) {
                this.router.navigate([`/${urlRedirect}`]);
              }
            });
        }

        break;
      case 401:
        // if(this.authService.token){
        if (this.authService.getToken()) {
          titulo = 'Su sesión ha expirado';
          subtitulo = 'Por favor vuelva a ingresar';
        } else {
          titulo = 'Disponible solo para usuarios registrados';
          subtitulo = 'Por favor ingrese con sus credenciales';
        }

        this._alertService
          .alertError(titulo, subtitulo, 'Ingresar')
          .then((resp) => {
            if (resp.value) {
              // console.log('resp-alert',resp);
              this.authService.logout();
              this.router.navigate(['/ingreso']);
            }
          });
        break;
      case 404:
        if (error.error.code === 'RUC_NOT_FOUND') {
          titulo = 'El RUC no fue encontrado';
          subtitulo = 'Intente buscar en nuestro listado de empresas';
        } else {
          titulo = 'Ups';
          subtitulo = 'Ocurrió un problema. Vuelve a intentar.';
        }

        this._alertService
          .alertError(titulo, subtitulo, 'Volver a lista de empresas')
          .then((resp) => {
            if (urlRedirect) {
              this.router.navigate([`/${urlRedirect}`]);
            }
          });

        break;

      case 429:
        this._alertService.closeAlert();

        if (this.authService.token) {
          if (this.countCaptchaModal === 0) {
            this.countCaptchaModal++;
            this.openModalRecaptcha();
          }
        } else {
          // if (this.countRegistraModal === 0) {
          //   this.countRegistraModal++
          //   console.log("contador-modal");
          // }
          this.openModalLimiteSinRegistro();

          // if (this.countRegistraModal === 0) {
          //   this.countRegistraModal++
          // }
        }

        break;

      // case 403:
      //   this._usuarioService.logout();
      //   this.modalError('No tienes permiso para ver esta sección','Vuelve a ingresar por favor');
      //   break;
      default:
        this._alertService
          .alertError('Ups', 'Ocurrió un problema. Vuelve a intentar.')
          .then((resp) => {
            if (urlRedirect) {
              this.router.navigate([`/${urlRedirect}`]);
            }
          });

        break;
    }

    return throwError(error);
  }

  openModalRecaptcha() {
    const modalRef = this.modalService.open(
      ModalCaptchaComponent,
      this._modalOptionsNormal
    );
    // modalRef.componentInstance.cuentas = this.cuentas;
    modalRef.result.then(
      (result) => {
        this.limiteCaptcha(result).subscribe((resp) => {
          if (resp.success) {
            window.location.reload();
            this.countCaptchaModal = 0;
          }
        });
      },
      (reason) => {
        console.log('reason', reason);
      }
    );
  }

  openModalLimiteSinRegistro() {
    this._alertService.alertWithoutIcon().then((resp) => {
      if (resp.value) {
        this.router.navigate([`/registro`]);
        this.countRegistraModal = 0;
      }
    });
  }
}
