<!--Inner Home Banner Start-->
<div class="jpg-haslayout jpg-banerbusqueda">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-xs-12 col-sm-12 col-md-12">
        <div class="jpg-innerbannercontent">
          <div class="jpg-title">
            <!-- <h2>TU CUENTA</h2> -->
            <h2>Mi Cuenta Deep Data</h2>
          </div>
          <!-- <ol class="jpg-breadcrumb">
              <li><a href="/app/home.html">Inicio</a></li>
              <li><a href="#">Búsqueda de datos</a></li>
              <li class="jpg-active">Dato encontrados</li>
            </ol> -->
        </div>
      </div>
    </div>
  </div>
</div>
<!--Inner Home Banner End-->
<div class="jpg-haslayout">
  <div class="container-fluid mt-2 jpg-cuenta">
    <div class="row justify-content-md-center">

      <div class="container">
        <br>
        <div class="row col-12 m-0 p-0">

          <div *ngIf="cuenta && !editarInfo" class="col-sm-12 col-md-12 col-lg-5">
            <div class="row">
              <div class="col-sm-12">
                <h3>Información de cuenta</h3>
              </div>
            </div>
            <br>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Nombres</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.nombres }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Organización</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.nombre_empresa }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Teléfono</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.celular ? cuenta.celular : 'No registrado' }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Email</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.email }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Fecha de registro</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.created_at | date: 'dd/MM/yyyy hh:mm:ss a' }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Mi suscripción</h4>
              </div>
              <div class="col-sm-12">
                <p *ngIf="suscripcion" class="p-cuenta">{{ suscripcion }}</p>
                <p *ngIf="!suscripcion" class="p-cuenta">Sin suscripción</p>
              </div>
            </div>
            <hr class="d-none d-lg-block">
            <div class="row text-left">
              <div class="col-sm-12">
                <b>
                <a href="javascript:void(0);" (click)="editarInformacion()" style="color: var(--link-color);">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i> &nbsp;
                  Editar información
                </a>
                </b>
              </div>
            </div>
            <hr class="d-lg-none">
            <br class="d-lg-none">
          </div>

          <div *ngIf="cuenta && editarInfo" class="col-sm-12 col-md-12 col-lg-5">
            <div class="row">
              <div class="col-sm-12">
                <h3>Información de cuenta</h3>
              </div>
            </div>
            <br>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Nombres</h4>
              </div>
              <div class="col-sm-12">
                <input type="text" class="mb-3" placeholder="Nombre" id="nombreUsuario" name="nombreUsuario" [(ngModel)]="nombreUsuario">
                <!-- <p class="p-cuenta">{{ cuenta.nombres }}</p> -->
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Organización</h4>
              </div>
              <div class="col-sm-12">
                <input type="text" class="mb-3" placeholder="Organizacion" id="organizacionUsuario" name="organizacionUsuario" [(ngModel)]="organizacionUsuario">
                <!-- <p class="p-cuenta">{{ cuenta.nombre_empresa }}</p> -->
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Teléfono</h4>
              </div>
              <div class="col-sm-12">
                <input type="text" class="mb-3" placeholder="Telefono" id="telefonoUsuario" name="telefonoUsuario" [(ngModel)]="telefonoUsuario">
                <!-- <p class="p-cuenta">{{ cuenta.celular ? cuenta.celular : 'No registrado' }}</p> -->
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Email</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.email }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Fecha de registro</h4>
              </div>
              <div class="col-sm-12">
                <p class="p-cuenta">{{ cuenta.created_at | date: 'dd/MM/yyyy hh:mm:ss a' }}</p>
              </div>
            </div>
            <div class="row text-left">
              <div class="col-sm-12">
                <h4>Mi suscripción</h4>
              </div>
              <div class="col-sm-12">
                <p *ngIf="suscripcion" class="p-cuenta">{{ suscripcion }}</p>
                <p *ngIf="!suscripcion" class="p-cuenta">Sin suscripción</p>
              </div>
            </div>
            <hr class="d-none d-lg-block">

            <div *ngIf="!editarInfo" class="row text-left">
              <div class="col-sm-12">
                <b>
                <a href="javascript:void(0);" (click)="editarInformacion()" style="color: var(--link-color);">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Editar información
                </a>
                </b>
              </div>
            </div>

            <div *ngIf="editarInfo" class="row text-left">
              <div class="col-sm-12">
                <b>
                <a href="javascript:void(0);" (click)="cancelarEdicion()" style="color: var(--link-color);">
                  <i class="fa fa-times" aria-hidden="true"></i> Cancelar
                </a>
                <a class="ml-4" href="javascript:void(0);" (click)="actualizarInfoUsuario()" style="color: var(--link-color);">
                  <i class="fa fa-check" aria-hidden="true"></i> Guardar cambios
                </a>
                </b>
              </div>
            </div>

            <hr class="d-lg-none">
            <br class="d-lg-none">
          </div>

          <div class="col-sm-12 col-md-12 col-lg-7">

            <h3 class="text-left m-0 pb-2 cuenta-title">Explora más de <b>Convoca Deep Data</b></h3>
            <p class="text-justify m-0 pt-1 pb-2 p-cuenta-2">Tenemos la información más completa sobre las industrias
              extractivas en el Perú y muchas funcionalidades pensadas para ti.</p>

            <div class="form-group mb-0">
              <div class="row">
                <div class="col-sm-12 col-md-6 mt-3">
                  <a class="btn btn-danger btn-custom-gray p-2 w-100" href="javascript:void(0);"
                    [routerLink]='["/planes"]'>Nuestros planes de suscripción</a>
                </div>
                <div class="col-sm-12 col-md-6 mt-3">
                  <a class="btn btn-danger btn-custom-red p-2 w-100" href="javascript:void(0);"
                    [routerLink]='["/industrias-extractivas"]'>Realiza una búsqueda de datos</a>
                </div>
              </div>
            </div>

            <!-- <div class="row col-sm-12">
                    <div class="col-sm-6">
                      <a href="javascript:void(0);" [routerLink]='["/planes"]'>
                      <div class="card bg-danger bg-tab-cuenta-2 mt-2">
                        <div class="card-body text-center">
                          <p class="card-text text-white">Nuestros planes de suscripción</p>
                        </div>
                      </div>
                      </a>
                    </div>
                    <div class="col-sm-6">
                      <a href="javascript:void(0);" [routerLink]='["/industrias-extractivas"]'>
                      <div class="card bg-danger bg-tab-cuenta-2 mt-2">
                        <div class="card-body text-center">
                          <p class="card-text text-white">Búsqueda de datos</p>
                        </div>
                      </div>
                      </a>  
                    </div>
                  </div> -->
          </div>

          <!-- <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="row col-sm-12 d-flex justify-content-center align-items-center pt-5">
                        <div>
                          <h3 class="text-left m-0 pb-2 cuenta-title">Explora más de <b>Convoca Deep Data</b></h3>
                          <p class="text-justify m-0 pt-1 pb-2 p-cuenta-2">Tenemos la información más completa sobre las industrias extractivas en el Perú y muchas funcionalidades pensadas para ti.</p>
                          <br>
                          <div class="row col-sm-12">
                            <div class="col-sm-6">
                              <a href="javascript:void(0);" [routerLink]='["/planes"]'>
                              <div class="card bg-danger bg-tab-cuenta-2 mt-2">
                                <div class="card-body text-center">
                                  <p class="card-text text-white">Nuestros planes de suscripción</p>
                                </div>
                              </div>
                              </a>
                            </div>
                            <div class="col-sm-6">
                              <a href="javascript:void(0);" [routerLink]='["/industrias-extractivas"]'>
                              <div class="card bg-danger bg-tab-cuenta-2 mt-2">
                                <div class="card-body text-center">
                                  <p class="card-text text-white">Búsqueda de datos</p>
                                </div>
                              </div>
                              </a>  
                            </div>
                          </div>
                        </div>
                    </div>
                </div> -->

        </div>
        <br><br>
      </div>
    </div>
  </div>
</div>