import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { MembresiaService } from '../../services/membresia.service';
import { AlertService } from '../../services/util/alert.service';

@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {

  cuenta: any;
  suscripcion: string;

  editarInfo: boolean = false;

  nombreUsuario: string = "";
  organizacionUsuario: string = "";
  telefonoUsuario: string = "";

  constructor( public _usuarioService: UsuarioService,
              public alertService: AlertService,
              public membresiaService: MembresiaService ) {

               }

  ngOnInit(): void {

    this.alertService.alertLoading("Cargando información ...");

    this.getUsuarioPerfil();

  }

  getUsuarioPerfil(){

    this._usuarioService.getUsuarioPerfil().subscribe(
      resp => {

        this.cuenta = this._usuarioService.perfilUsuario;

        this.nombreUsuario = this.cuenta.nombres ? this.cuenta.nombres.trim() : '';
        this.organizacionUsuario = this.cuenta.nombre_empresa ? this.cuenta.nombre_empresa.trim() : '';
        this.telefonoUsuario = this.cuenta.celular ? this.cuenta.celular.trim() : '';

        this.listarMembresias();

      }
    );

  }

  // ngOnInit(): void {
  //   this.alertService.alertLoading("Cargando información ...");
  //   if(this._usuarioService.perfilUsuario){
  //     this.cuenta = this._usuarioService.perfilUsuario;
  //     console.log(this.cuenta);
  //     this.nombreUsuario = this.cuenta.nombres ? this.cuenta.nombres.trim() : '';
  //     this.organizacionUsuario = this.cuenta.nombre_empresa ? this.cuenta.nombre_empresa.trim() : '';
  //     this.telefonoUsuario = this.cuenta.celular ? this.cuenta.celular.trim() : '';
  //     this.listarMembresias();
  //   }else{
  //     this._usuarioService.getUsuarioPerfil().subscribe(
  //       resp => {
  //         this.cuenta = this._usuarioService.perfilUsuario;
  //         this.listarMembresias();
  //       }
  //     );
  //   }

  // }

  listarMembresias(){
    this.membresiaService.getMembresias().subscribe(
      resp => {
        if( resp.success ){
          for (let i = 0; i < resp.membresias.length; i++) {
            if( this.cuenta.codigo_membresia === resp.membresias[i].codigo_membresia){
              // console.log('resp.membresias[i]',resp.membresias[i]);
              this.suscripcion = resp.membresias[i].nombre;
              // this.suscripcion = resp.membresias[i].nombre_plan;
              break;
            }
          }
          this.alertService.closeAlert();
        }
      },error => {
        this.alertService.closeAlert(); 
      }
    );
  }

  editarInformacion(){

    this.editarInfo = true;

  }

  actualizarInfoUsuario(){

    if(!this.nombreUsuario || !this.telefonoUsuario || !this.organizacionUsuario){
      return;
    }

    this.alertService.alertLoading("Actualizando información");

    this._usuarioService.actualizarUsuario(this.nombreUsuario, this.telefonoUsuario, this.organizacionUsuario).subscribe(
      resp => {
        if(resp.success){
          this.alertService.alertSuccess("La información se actualizó con éxito");
          this.cancelarEdicion();
          this.cuenta = null;
          this.getUsuarioPerfil();
        }
      }
    );

  }

  cancelarEdicion(){

    this.editarInfo = false;

  }

  limpiarCampos(){

  }

}
