<div class="container-fluid reportajes">
  <div class="row reportajes-hero">
    <div class="col d-block align-self-center reportajes-hero-text">
      <div class="hero-main-text">
        <h2 class="text-center text-white">Elige nuestros planes</h2>
        <p class="text-center text-white">Inicio / Planes</p>
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <img
          src="../../../assets/buttons/arrow-custom.png"
          alt=""
          class="sm-width-image arrow-hero"
        />
      </div>
    </div>
  </div>
</div>

<!-- NUEVOS PLANES -->
<div *ngIf="!showCarrito && membresias" class="jpg-haslayout">
  <div class="container-fluid mt-2 jpg-cuenta">
    <div class="row justify-content-md-center">
      <div class="container col-xs-12 col-sm-12" style="color: #202b3f">
        <br />
        <div class="row">
          <!-- PLAN FREE -->
          <div
            class="col-12 d-block d-lg-flex col-lg-4 mb-4 justify-content-end align-items-start"
          >
            <div class="card card-main">
              <div>
                <img
                  src="../../../assets/img/membresias/m-free.jpg"
                  class="card-img-top card-main-img"
                  alt="free-plan"
                />
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-center mb-3">
                  <h2 class="card-title price-principal mb-1">GRATIS / $0</h2>
                </div>
                <div class="plan-features p-0 pb-3">
                  <ul class="ul-planes">
                    <li>
                      > Descuento de 5% para cursos y talleres del
                      <a
                        class="benefit-link"
                        href="https://convoca.pe/escuela-convoca/escuela-convoca-lanza-laboratorio-para-el-periodismo-de-datos-en-america-latina"
                        target="_blank"
                      >
                        Laboratorio de Datos</a
                      >
                      y congresos de Convoca
                    </li>
                    <li>> Acceso a todas las bases de datos</li>
                    <li>> Un (1) solo acceso simultáneo con la misma cuenta</li>
                    <li>
                      > Boletín especial sobre nuestros reportajes y novedades
                    </li>
                  </ul>
                </div>
                <div class="text-center">
                  <a
                    class="btn btn-main"
                    href="javascript:void(0);"
                    [routerLink]="['/industrias-extractivas']"
                    >ELEGIR</a
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- PLAN PREMIUM -->
          <div
            class="col-12 d-block d-lg-flex col-lg-4 mb-4 justify-content-center align-items-start"
          >
            <div class="card card-main">
              <div>
                <img
                  src="../../../assets/img/membresias/m-premium.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </div>
              <div class="card-body">
                <!-- PRICES -->
                <div class="mt-3">
                  <div
                    *ngIf="
                      planPremiumShow === 1 &&
                      membresiaPremium[0].precio !== '0.00'
                    "
                    class="d-flex flex-column justify-content-center"
                  >
                    <h2 class="card-title price-principal mb-0">
                      <span
                        >${{ membresiaPremium[0].precio }} /
                        {{
                          membresiaPremium[0].nombre_plan.toUpperCase()
                        }}</span
                      >
                    </h2>
                    <h2 class="card-title mb-0 ml-4 text-center"></h2>
                  </div>

                  <div
                    *ngIf="
                      planPremiumShow === 3 &&
                      membresiaPremium[1].precio !== '0.00'
                    "
                    class="d-flex flex-column justify-content-center mb-3"
                  >
                    <h2 class="card-title price-principal mb-0">
                      <span
                        >${{ membresiaPremium[2].precio }} /
                        {{
                          membresiaPremium[2].nombre_plan.toUpperCase()
                        }}</span
                      >
                    </h2>
                    <h2 class="card-title mb-0 ml-4 text-center">
                      <span class="price-ahorro">Ahorra 44%</span>
                    </h2>
                  </div>

                  <div
                    *ngIf="
                      planPremiumShow === 4 &&
                      membresiaPremium[3].precio !== '0.00'
                    "
                    class="d-flex flex-column justify-content-center mb-3"
                  >
                    <h2 class="card-title price-principal mb-0">
                      <span
                        >${{ membresiaPremium[3].precio }} /
                        {{
                          membresiaPremium[3].nombre_plan.toUpperCase()
                        }}</span
                      >
                    </h2>
                    <h2 class="card-title mb-0 ml-4 text-center">
                      <span class="price-ahorro">Ahorra 45%</span>
                    </h2>
                  </div>
                </div>

                <!-- BENEFITS -->
                <div>
                  <div class="row mt-3">
                    <div class="col-12 pr-0">
                      <div class="plan-features pr-1">
                        <ul class="ul-planes">
                          <li>
                            <span class="red-arrow">></span> Descuento de 50%
                            para cursos y talleres del
                            <a
                              class="benefit-link"
                              href="https://convoca.pe/escuela-convoca/escuela-convoca-lanza-laboratorio-para-el-periodismo-de-datos-en-america-latina"
                              target="_blank"
                            >
                              Laboratorio de Datos
                            </a>
                            y congresos de Convoca
                          </li>
                          <li>
                            <span class="red-arrow">></span> Acceso a todas las
                            bases de datos
                          </li>
                          <li>
                            <span class="red-arrow">></span> Tres (3) accesos
                            simultáneos con la misma cuenta
                          </li>
                          <li>
                            <span class="red-arrow">></span> Boletín especial
                            sobre nuestros reportajes y novedades
                          </li>
                          <li>
                            <span class="red-arrow">></span> Búsqueda masiva de
                            datos
                          </li>
                          <li>
                            <span class="red-arrow">></span> Notificaciones de
                            actualización de información
                          </li>
                          <li>
                            <span class="red-arrow">></span> Capacitación a
                            distancia para el uso de la plataforma
                          </li>
                          <li>
                            <span class="red-arrow">></span> Soporte técnico
                            personalizado
                          </li>
                          <li>
                            <span class="red-arrow">></span> Descarga gratuita
                            de dos (2)
                            <a
                              class="benefit-link"
                              href="https://convoca.pe/multimedia/convoca-lanza-catalogo-de-libros-digitales-sobre-corrupcion-medio-ambiente-e-industrias"
                              target="_blank"
                            >
                              libros digitales de Convoca
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- RADIOBUTTONS -->
                <div class="form-check p-0 d-flex justify-content-center mt-2">
                  <div class="d-flex flex-column align-items-center mx-2">
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="radioPremium"
                        id="radioPremium1"
                        value="1"
                        checked
                        (change)="changeMembresiaPremium(1)"
                      />
                      <label class="custom-control-label" for="radioPremium1">
                      </label>
                    </div>
                    <label class="rbtn-label" for="radioPremium1">{{
                      membresiaPremium[0].nombre_plan.toUpperCase()
                    }}</label>
                  </div>

                  <div class="d-flex flex-column align-items-center mx-2">
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="radioPremium"
                        id="radioPremium2"
                        value="2"
                        (change)="changeMembresiaPremium(3)"
                      />
                      <label class="custom-control-label" for="radioPremium2">
                      </label>
                    </div>
                    <label class="rbtn-label" for="radioPremium2">{{
                      membresiaPremium[2].nombre_plan.toUpperCase()
                    }}</label>
                  </div>

                  <div class="d-flex flex-column align-items-center mx-2">
                    <div class="custom-control custom-radio">
                      <input
                        class="custom-control-input"
                        type="radio"
                        name="radioPremium"
                        id="radioPremium3"
                        value="3"
                        (change)="changeMembresiaPremium(4)"
                      />
                      <label class="custom-control-label" for="radioPremium3">
                      </label>
                    </div>
                    <label class="rbtn-label" for="radioPremium3">{{
                      membresiaPremium[3].nombre_plan.toUpperCase()
                    }}</label>
                  </div>
                </div>

                <br />
                <div class="text-center">
                  <a
                    class="btn btn-main"
                    href="javascript:void(0);"
                    (click)="
                      seleccionarProducto(
                        membresiaPremium[this.planPremiumShow - 1]
                      )
                    "
                    >ELEGIR</a
                  >
                </div>

                <br />
              </div>
            </div>
          </div>

          <!-- PLAN INVESTIGADOR -->
          <div
            class="col-12 d-block d-lg-flex col-lg-4 mb-4 justify-content-start align-items-start"
          >
            <div class="card card-main">
              <div>
                <img
                  src="../../../assets/img/membresias/m-investigador.png"
                  class="card-img-top card-main-img"
                  alt="free-plan"
                />
              </div>
              <div class="card-body">
                <div class="plan-features py-5">
                  <p class="p-investigador">
                    ¡Si eres un investigador o periodista independiente puedes
                    acceder a un descuento de 70% para la Membresía Premium!
                    Para acceder a este beneficio sólo llena el siguiente
                    formulario y nos pondremos en contacto contigo
                  </p>
                  <div class="text-center">
                    <a
                      class="btn btn-main"
                      href="javascript:void(0);"
                      (click)="selectPlanPeriodista()"
                      >ELEGIR</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br />
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <!-- <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div> -->
  <div class="modal-body">
    <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    <div class="card border-0 jpg-howitwork-hold jpg-bgwhite jpg-haslayout">
      <ul class="jpg-navarticletab jpg-navarticletabvtwo nav navbar-nav">
        <li class="nav-item">
          <a class="active" id="all-tab" data-toggle="tab" href="#tab01"
            >Transferencia bancaria</a
          >
        </li>
        <li class="nav-item">
          <a id="trading-tab" data-toggle="tab" href="#tab03">Pago en línea</a>
        </li>
      </ul>

      <div class="tab-content jpg-haslayout">
        <div class="jpg-contentarticle tab-pane active fade show" id="tab01">
          <div class="row">
            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
              <div class="col-12 col-sm-12 col-md-12">
                <h5>Estas son nuestras cuentas BCP:</h5>
                <p class="mb-0">Asociación Convoca</p>
                <p class="mb-0">Cuenta Corriente</p>
                <p class="mb-0">Dólares:</p>
                <p class="mb-0">
                  Cuenta corriente en dólares en el Banco de Crédito del Perú
                  (BCP): 193-2265763-1-03
                </p>
                <p class="mb-0">
                  Código de cuenta Interbancaria en dólares:
                  00219300226576310316
                </p>
                <p class="mb-0">Soles:</p>
                <p class="mb-0">
                  Cuenta corriente en soles del Banco de Crédito del Perú (BCP):
                  193-2580605-0-15
                </p>
                <p class="mb-0">
                  Código de cuenta Interbancaria en soles: 00219300258060501516
                </p>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
              <div class="col-12 col-sm-12 col-md-12">
                <p class="mb-0">
                  Envía tu comprobante de pago a
                  <b>deepdataconvoca@gmail.com</b> y nos pondremos en contacto
                  contigo.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="jpg-contentarticle tab-pane fade" id="tab03">
          <div class="row">
            <div class="jpg-innerspace jpg-haslayout">
              <div class="col-12 col-sm-12 col-md-12">
                <h5>¡Muy pronto!</h5>
                <p class="mb-0">
                  En breve podrás acceder a esta opción de pago, nos
                  comunicaremos contigo.
                </p>
                <!-- <h5>¡Muy pronto! En breve podrás acceder a esta opción de pago, nos comunicaremos contigo.</h5> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer d-flex justify-content-center">
    <button
      type="button"
      class="btn btn-dark"
      (click)="modal.close('Save click')"
    >
      Entendido
    </button>
  </div>
</ng-template>

<div *ngIf="showCarrito">
  <div class="jpg-haslayout">
    <br /><br />
    <div class="container mt-2 jpg-cuenta">
      <div class="row justify-content-md-center">
        <div class="container col-xs-12 col-sm-12">
          <br />
          <h2>Carrito</h2>
          <div style="font-size: 1rem">
            <div
              style="border-bottom: 2px solid"
              class="col-sm-12 d-flex justify-content-between"
            >
              <span style="font-weight: 700">Producto</span>
              <span style="font-weight: 700">Precio</span>
            </div>
            <br />
            <div class="col-sm-12 mt-2 mb-2 d-flex justify-content-between">
              <div>
                <span
                  ><b>{{ membresiaSelected.nombre }}</b> <br />Licencia de uso
                  no comercial</span
                >
              </div>
              <span style="font-weight: 700"
                >$ {{ membresiaSelected.precio }}</span
              >
            </div>
            <div
              *ngIf="montoIGV"
              class="col-sm-12 mt-2 mb-2 d-flex justify-content-between"
            >
              <div>
                <span><b>IGV</b></span>
              </div>
              <span style="font-weight: 700">$ {{ montoIGV }}</span>
            </div>
            <br />
            <div
              style="border-top: 2px solid"
              class="col-sm-12 d-flex justify-content-between"
            >
              <a href="javascript:void(0);" (click)="regresar()"
                ><span style="font-weight: 600" class="text-danger"
                  >CAMBIAR DE PLAN</span
                ></a
              >
              <span *ngIf="montoIGV" style="font-weight: 700"
                >TOTAL: $ {{ precioConIGV }}</span
              >
              <span *ngIf="!montoIGV" style="font-weight: 700"
                >TOTAL: $ {{ membresiaSelected.precio }}</span
              >
            </div>
            <br />
            <div class="col-sm-12 text-right mt-2">
              <div *ngIf="authorization" class="form-group jpg-btnarea">
                <form
                  name="f1"
                  id="f1"
                  action="#"
                  method="post"
                  class="alignet-form-vpos2"
                >
                  <table>
                    <input
                      type="hidden"
                      name="acquirerId"
                      [(ngModel)]="acquirer_id"
                    />
                    <input
                      type="hidden"
                      name="idCommerce"
                      [(ngModel)]="id_commerce"
                    />
                    <input
                      type="hidden"
                      name="purchaseOperationNumber"
                      [(ngModel)]="purchase_operation_number"
                    />
                    <input
                      type="hidden"
                      name="purchaseAmount"
                      [(ngModel)]="precio_membresia"
                    />
                    <input
                      type="hidden"
                      name="purchaseCurrencyCode"
                      [(ngModel)]="purchase_currency_code"
                    />

                    <input type="hidden" name="language" value="SP" />
                    <input
                      type="hidden"
                      name="shippingFirstName"
                      value="nombre"
                    />
                    <input
                      type="hidden"
                      name="shippingLastName"
                      value="apellido"
                    />
                    <input
                      type="hidden"
                      name="shippingEmail"
                      value="convocadeepdata@gmail.com"
                    />
                    <input type="hidden" name="shippingAddress" value="LIMA" />
                    <input type="hidden" name="shippingZIP" value="ZIP 123" />
                    <input type="hidden" name="shippingCity" value="LIMA" />
                    <input type="hidden" name="shippingState" value="LIMA" />
                    <input type="hidden" name="shippingCountry" value="PE" />
                    <input type="hidden" name="userCommerce" value="" />
                    <input type="hidden" name="userCodePayme" value="" />

                    <input
                      type="hidden"
                      name="descriptionProducts"
                      value="Producto ABC"
                    />
                    <input
                      type="hidden"
                      name="programmingLanguage"
                      value="PHP"
                    />
                    <input
                      type="hidden"
                      name="purchaseVerification"
                      [(ngModel)]="purchaseVerification"
                    />

                    <input
                      type="hidden"
                      name="reserved1"
                      [(ngModel)]="reserved1"
                    />

                    <span
                      ><b>Realiza tu pago con Visa o Mastercard.</b>
                      &nbsp;</span
                    ><input
                      type="button"
                      class="jpg-btn"
                      onclick="javascript:AlignetVPOS2.openModal('','2')"
                      value="Realizar pago"
                    />
                  </table>
                </form>
              </div>
              <div *ngIf="!authorization" class="form-group jpg-btnarea">
                <a
                  href="javascript:void(0);"
                  (click)="finalizarFree()"
                  class="jpg-btn"
                >
                  Finalizar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
  </div>
</div>
