import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../services/util/alert.service';
import { UsuarioService } from '../../services/usuario.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-confirmar-cuenta',
  templateUrl: './confirmar-cuenta.component.html',
  styleUrls: ['./confirmar-cuenta.component.css'],
})
export class ConfirmarCuentaComponent implements OnInit {
  tokenFromEmail: string;

  constructor(
    private _usuarioService: UsuarioService,
    private _alertService: AlertService,
    public _router: Router,
    public route: ActivatedRoute
  ) {
    this.getTokenFromEmail(this.route);
  }

  ngOnInit(): void {
    this._alertService.alertLoading('Estamos confirmando tu cuenta...');
  }

  getTokenFromEmail(route: ActivatedRoute) {
    route.queryParams.subscribe((params) => {
      try {
        if (params['token']) {
          this.tokenFromEmail = params['token'];
          this.confirmarCorreo();
        } else {
          this._router.navigate(['/home']);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  confirmarCorreo() {
    this._usuarioService
      .confirmarCorreo(this.tokenFromEmail)
      .subscribe((resp) => {
        // console.log('resp',resp);

        if (resp.succes) {
          this._alertService
            .alertSuccess('Tu correo ha sido validado con éxito', '')
            .then((resp) => {
              if (resp.value) {
                this._router.navigate(['/ingreso']);
                this._usuarioService.registeredFromGoogle = true;
              }
            });
        } else {
          this._alertService
            .alertError('No pudimos confirmar tu correo', 'Ocurrió un error')
            .then((resp) => {
              if (resp.value) {
                this._router.navigate(['/home']);
              }
            });
        }
      });
  }
}
