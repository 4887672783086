<!--Main Start-->
<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
  <!-- Formulario de ingreso Start-->
  <div class="jpg-haslayout">
    <div class="container-fluid">
      <div
        class="col-xs-12 col-sm-12 offset-md-3 col-md-6 offset-lg-3 col-lg-5 offset-xl-4 col-xl-4"
      >
        <p>&nbsp;</p>

        <div class="jpg-registerformhold">
          <div class="jpg-registerformmain">
            <div class="jpg-registerhead">
              <div class="jpg-title">
                <h3>Ingresa una nueva contraseña</h3>
              </div>
            </div>
            <div class="jpg-joinforms">
              <!-- <form class="wt-formtheme wt-formregister"> -->
              <form
                [formGroup]="formaPassword"
                (ngSubmit)="actualizarPassword()"
                class="wt-formtheme wt-formregister"
              >
                <div class="form-group">
                  <label for="email" class="cols-sm-2 control-label"
                    >Ingresa tu nueva contraseña</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="password"
                      class="form-control"
                      formControlName="password"
                      name="password"
                      id="password"
                      placeholder="Ingresar Contraseña"
                      required
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" class="cols-sm-2 control-label"
                    >Confirma tu nueva contraseña</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="password"
                      class="form-control"
                      formControlName="passwordConfirma"
                      name="passwordConfirma"
                      id="passwordConfirma"
                      placeholder="Confirmar Contraseña"
                      required
                    />
                  </div>
                </div>

                <!-- <div class="form-group ">
                    <label for="password" class="cols-sm-2 control-label">Contraseña</label>
                    <div class="cols-sm-10">
                      <input type="password" class="form-control" formControlName="password" name="password" id="password"
                        placeholder="Ingresar Contraseña" />
                    </div>
                  </div> -->

                <div class="form-group" *ngIf="!flagFormaValida">
                  <span class="text-danger">{{ mensajeError }}</span>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn jpg-btn">
                    Actualizar contraseña
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="jpg-registerformfooter">
            <!-- <span><a [routerLink]="['/password']">Olvidé mi contraseña</a></span> -->
            <span><a [routerLink]="['/ingreso']">Volver al ingreso</a></span>
          </div>
        </div>

        <p>&nbsp;</p>
      </div>
    </div>
  </div>
  <!-- Formulario de ingreso End-->
</main>
<!--Main End-->
