<!--Main Start-->
<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
    <div class="jpg-haslayout">

      <!-- Busqeuda de empresas Listing Start-->
      <div class="jpg-haslayout">
        <div class="container-fluid">

          <div class="row">
            <div id="jpg-twocolumns" class="jpg-twocolumns jpg-haslayout">
              <!--First Column Start-->
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 float-left">

                <!-- Redes Sociales Start-->
                <div class="card border-0 mr-0 ml-0 mb-3 b-rad">
                  <div class="card-body p-0">
                    <div class="jpg-widget">
                      <div class="d-flex justify-content-between">
                        <div class="form-group mb-0">
                          <h4>¿Cómo encontrar la información que me interesa?</h4>
                        </div>
                        <div class="form-group mb-0 jpg-btnarea text-right">
                          <a href="javascript:void(0);" [routerLink]='["/industrias-extractivas"]' class="jpg-btn btn-dark" style="background-color: #343a40;border-color: #343a40;">BÚSQUEDA POR EMPRESA</a>
                        </div>
                      </div>
                      <hr class="mb">

                      <p class="p-custom">Si tienes tus propias tablas de datos, y quieres encontrar toda la información que
                        hemos reunido sobre un número de
                        empresas en especial, te ahorramos el tiempo de buscar una a una: sube tu tabla de datos
                        aquí y te otorgaremos un
                        reporte con las empresas que encontremos.</p>




                      <div class="jpg-attachments">
                        <div class="jpg-title">
                          <h3>Indicaciones para comparar datos</h3>
                        </div>
                        <p class="p-custom">
                          Solo podemos encontrar la coincidencia de empresas si tu archivo tienen los códigos de
                          Registro Único de Contribuyentes
                          (RUC) de las empresas que te interesan encontrar.</p>

                        <div class="jpg-title">
                          <h3>Pasos a seguir:</h3>
                        </div>
                        <ul class="jpg-attachfile">
                          <li>
                            <span class="p-custom">1. Descargar <a href="../../../../assets/forms/rucs.xlsx" download>esta plantilla.</a></span>
                            <em><a href="../../../../assets/forms/rucs.xlsx" download><i class="lnr lnr-download"></i></a></em>
                          </li>
                          <li>
                            <span class="p-custom">2. Coloca los rucs de las empresas en la columna 1, “ruc”.</span>
                            <em><a href="javascript:void(0);"><i class="lnr lnr-keyboard"></i></a></em>
                          </li>
                          <li>
                            <span class="p-custom">3. Coloca todos los rucs de las empresas que te interesan y sube el
                              archivo.</span>
                            <em><a href="javascript:void(0);"><i class="lnr lnr-upload"></i></a></em>
                          </li>
                        </ul>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
              <!--Fisrt Column End-->
            </div>
          </div>

          <!--Filter Section Start-->
          <div class="row">
            <!--Filter Colum 1 Start-->
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 float-left">

              <div class="card border-0 mr-0 ml-0 mb-3">
                <div class="card-body p-0 pb-1">
                  <div class="jpg-widget">
                    <div class="jpg-widgettitle mb-2 pb-0">
                      <h4>Filtrar</h4>
                    </div>

                    <p class="mb-0">
                      Selecciona los datos que quieres descargar
                    </p>

                    <div class="jpg-formtheme">
                      <fieldset>
                        <div class="jpg-checkboxholder">

                          <span class="jpg-checkbox">
                            <input id="ambientales" type="checkbox" name="ambientales"
                              value="ambientales"  [(ngModel)]="ambientales">
                            <label for="ambientales"> Infracciones ambientales</label>
                          </span>
                          <span class="jpg-checkbox">
                            <input id="laborales" type="checkbox" name="laborales"
                              value="laborales"  [(ngModel)]="laborales">
                            <label for="laborales"> Infracciones laborales</label>
                          </span>
                          <span class="jpg-checkbox">
                            <input id="multas" type="checkbox" name="multas" value="multas"  [(ngModel)]="multas">
                            <label for="multas"> Multas Perdonadas</label>
                          </span>

                          <span class="jpg-checkbox">
                            <input id="proyectos" type="checkbox" name="proyectos" value="Proyectos"  [(ngModel)]="proyectos">
                            <label for="proyectos"> Proyectos</label>
                          </span>
                          <span class="jpg-checkbox">
                            <input id="derrames" type="checkbox" name="derrames" value="derrames"  [(ngModel)]="derrames">
                            <label for="derrames"> Derrames</label>
                          </span>
                          <span class="jpg-checkbox">
                            <input id="accidentes" type="checkbox" name="accidentes" value="accidentes" [(ngModel)]="accidentes" >
                            <label for="accidentes">Accidentes</label>
                          </span>
                          <span class="jpg-checkbox">
                            <input id="permisos" type="checkbox" name="permisos" value="permisos"  [(ngModel)]="permisos">
                            <label for="permisos"> Permisos de agua</label>
                          </span>
                          
                          <span class="jpg-checkbox">
                            <input id="representantes" type="checkbox" name="representantes" value="representantes"  [(ngModel)]="representantes">
                            <label for="representantes"> Representantes</label>
                          </span>
                          <span class="jpg-checkbox">
                            <input id="estructura" type="checkbox" name="estructura" value="estructura"  [(ngModel)]="estructura">
                            <label for="estructura"> Estructura empresarial</label>
                          </span>
                          
                          
                        </div>
                      </fieldset>
                    </div>


                  </div>
                </div>
              </div>

            </div>
            <!--Filter Colum 1 End-->

            <!--Filter Colum 2 Sube archivo Start-->
            <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 float-left">

              <div class="card border-0 mr-0 ml-0 mb-3">
                <div class="card-body p-0">
                  <div class="jpg-widget">
                    <div class="jpg-widgettitle mb-2 pb-0">
                      <h4>Sube tu archivo aquí</h4>
                    </div>
                    <p class="mb-0">
                      (formatos soportados: .xls; .xlsx)
                    </p>
                    <div class="jpg-historycontent">
                      <form class="jpg-formtheme jpg-userform">
                        <fieldset>
                          <div class="form-group form-group-label">
                            <div class="jpg-labelgroup text-center py-5">
                              <label for="file">
                                <span class="jpg-btn"><b><i class="fa fa-search"></i> Seleccionar archivo</b></span>
                                <!-- <input type="file" name="file" id="file"> -->
                                <input type="file" (change)="seleccionFile( $event.target.files[0] )" id="file" name="file" required>
                              </label>
                              <span class="font-weight-bold">{{ fileName }}</span>
                              <em class="jpg-fileuploading">Subiendo archivo<i
                                  class="fa fa-spinner fa-spin"></i></em>
                            </div>
                          </div>
                          <p class="p-0 m-0">&nbsp;</p>
                          <div class="form-group jpg-btnarea">
                            <a href="javascript:void(0);" (click)="checkMembresia()" class="jpg-btn"><i
                                class="fa fa-play"></i> Buscar</a>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!--Filter Colum 2 Sube archivo End-->

          </div>
          <!--Filter Section End-->

        </div>
      </div>
      <!-- Busqeuda de empresas Listing End-->

    </div>
  </main>
  <!--Main End-->