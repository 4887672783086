import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { AlertService } from '../../services/util/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  formaPassword: FormGroup;
  emailVerificacion: string;

  flagFormaValida: boolean = true;
  mensajeError: string = '';

  token: string = '';

  constructor(  public _usuarioService : UsuarioService,
                public _alertService: AlertService,
                public _router: Router,
                public route: ActivatedRoute ) {

                  this.getTokenFromMail(this.route);

                 }

  ngOnInit(): void {
    this.formaPassword = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      passwordConfirma: new FormControl(null, [Validators.required])
    })
  }

  actualizarPassword(){

    if( this.formaPassword.valid ){

      this.flagFormaValida = true;
      
      if( this.formaPassword.controls['password'].value === this.formaPassword.controls['passwordConfirma'].value ){

        this._alertService.alertLoading("Actualizando contraseña...");

        this._usuarioService.cambiarPassword(this.token, this.formaPassword.controls['password'].value).subscribe(
          
          resp => {
            if(resp.success){ 
              this._alertService.alertSuccess('Contraseña actualizada','Por favor ingrese con sus nuevas credenciales.').then( 
                result => {
                  this._router.navigate(["ingreso"]);
                }); 
            }
          }
          
        );
  
      }else{
        
        this.flagFormaValida = false;
        this.mensajeError = 'La contraseñas ingresadas deben ser iguales';
        
      }
    }else{
      this.mensajeError = 'Los datos ingresados no son válidos';
      this.flagFormaValida = false;
    }
  }

  getTokenFromMail(route: ActivatedRoute){

    route.queryParams.subscribe(params => {
      try {

        if(params["token"]){
          
          this.token = params["token"];
          
        }else{
          
          this._alertService.alertError("Ocurrió un problema", "Token de verificación no encontrado.").then(
            resp => {
              if(resp.value){
                this._router.navigate(["password"]);
              }
            }
          );
        }
      } catch (error) {
        console.error(error);
      }
    });

  }

}
