import {
  Component,
  OnInit,
  Directive,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { forkJoin, Observable, OperatorFunction, of } from 'rxjs';
import * as Mapboxgl from 'mapbox-gl';
import * as FileSaver from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Empresa } from '../../../models/empresa.model';

import { DataEmpresaService } from '../../../services/data-empresa.service';
import { AlertService } from '../../../services/util/alert.service';
import { FilterService } from '../../../services/util/filter.service';
import { ShareSocialMediaService } from '../../../services/util/share-social-media.service';
import { MapDataService } from '../../../services/map-data.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { CaptchaMessageService } from '../../../services/util/captcha-message.service';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  catchError,
  tap,
  filter,
} from 'rxjs/operators';

export type SortColumn = keyof Empresa | '';
export type SortDirection = 'asc' | 'desc' | '';
const rotate: { [key: string]: SortDirection } = {
  asc: 'desc',
  desc: 'asc',
  '': 'asc',
};
// const rotate: {[key: string]: SortDirection} = { 'asc': 'desc', 'desc': '', '': 'asc' };

const compare = (v1: string | number, v2: string | number) =>
  v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

const colors: any = {
  accidentelab: '#89D4DA', //verdeazulado - Accidente laboral
  laboral: '#8192A7', //gris - Infracciones laborales
  ambiental: '#AAC885', //verde - Infracciones Ambientales

  derrames: '#B06585', //vino
  pasivos: '#93A2D7', //moradoplomo
  permisos: '#89C1D8', //azul-1
  proyectos: '#BAA599', //marrón
  accidentes: '#9E8CC6', //morado

  white: '#ffffff', // blanco - para textos
};

@Directive({
  selector: 'th[sortable]',
  host: {
    '[class.asc]': 'direction === "asc"',
    '[class.desc]': 'direction === "desc"',
    '[class.neutral]': 'direction === ""',
    '(click)': 'rotate()',
  },
})
export class NgbdSortableHeader {
  @Input() sortable: SortColumn = 'IndInfAmb';
  @Input() direction: SortDirection = '';
  @Output() sort = new EventEmitter<SortEvent>();

  rotate() {
    this.direction = rotate[this.direction];
    this.sort.emit({ column: this.sortable, direction: this.direction });
  }
}

@Component({
  selector: 'app-industrias-extractivas',
  templateUrl: './industrias-extractivas.component.html',
  styleUrls: ['./industrias-extractivas.component.css'],
})
export class IndustriasExtractivasComponent implements OnInit {
  @ViewChild('firstcol') firstcol: ElementRef;
  @ViewChild('toggleOrdenamientoMenu') toggleButton: ElementRef;
  @ViewChild('ordenamientoMenu') menu: ElementRef;

  empresas: Empresa[] = [];

  // PARA TOTALES
  totalReactiva: string;
  totalCovid: string;
  totalDerrames: string;
  totalProyectos: string;

  notaTotalCovid: string;
  showMobileOrderButtons: boolean = false;

  // PARA FILTROS
  filtros: any = {
    ruc: '',
    region: '',
    sectorEconomico: '',
    nombreEmpresa: '',
    agno: '',
    page: 1,
    size: 10,
    tipoInfraccion: '',
  };

  oldSort: string;
  currentSort: string;

  cboRegiones: any[] = [];
  cboAnios: any[] = [];
  cboActividadEconomica: any[] = [];

  totalRegistrosEmpresas: number;

  // INDICADORES PARA EL MOBILE
  cboIndicadores: any[] = [
    { id: 'Infracciones', value: '1' },
    // { id: 'Multas perdonadas', value: '2' },
    { id: 'Multas judicializadas', value: '3' },
    { id: 'Derrames', value: '4' },
    { id: 'Accidentes', value: '5' },
    { id: 'Proyectos', value: '6' },
    { id: 'Permisos de agua', value: '7' },
  ];
  selectedIndicador: any;

  cboOrdenamiento: any[] = [
    { id: 'Ascendente', value: '1' },
    { id: 'Descendente', value: '2' },
  ];
  selectedOrdenamiento: any;
  // - FIN INDICADORES PARA EL MOBILE

  cboTipoInfraccion: any[] = [
    { id: 'TODOS', value: '' },
    { id: 'INFRACCIONES AMBIENTALES', value: 'ambiental' },
    { id: 'INFRACCIONES LABORALES', value: 'laboral' },
    // {id:'ACCIDENTES LABORALES',value:'acc'},
    { id: 'DERRAMES', value: 'Derrames' },
    { id: 'PASIVOS', value: 'Pasivos' },
    { id: 'ACCIDENTES', value: 'Accidentes' },
    { id: 'PERMISOS DE AGUA', value: 'Permisos de agua' },
    { id: 'PROYECTOS', value: 'Proyectos' },
    // 'TODOS','INFRACCIONES AMBIENTALES','INFRACCIONES LABORALES','ACCIDENTES LABORALES'
  ];

  selectedRegion: any;
  selectedAnio: any;
  selectedActividad: any;
  selectedTipoInfraccion: any;
  organizacion: any = '';

  // PARA LA PAGINACIÓN
  page = 1;
  pageSize = 10;
  collectionSize = this.empresas.length;
  empresasPaginated: Empresa[] = [];

  // PARA CAMBIO DE VISTA TABLA SEMAFORO
  verCifras = false;

  // PARA EL MAPA
  mapa: Mapboxgl.Map;
  mapOptions: any = {
    htmlcontainer: 'mapa-mapbox',
    mapaSrc: '../assets/resources/peru-dpto.json',
    theme: this._mapService.mapThemes.LIGHT_THEME,
    initialCoords: [-74.854733, -9.180939], // CENTRO DE PERÚ
    // initialCoords: [-71.6544562063983, -7.47829582803183], // CENTRO DE PERÚ
    initialZoom: 4,
    // initialZoom: 3,
    maxZoom: 9.5,
    minZoom: 4,
    scrollZoom: true,
    doubleClickZoom: false,
    fillColor: '#acacac', //#627BC1
    // ESTILOS PARA EL MARCADO DEL MAPA
    fillColorOpacity: 0.4,
    fillColorOpacityHover: 0.3,
    lineColor: '#9b9b9b',
    lineWidth: 1,
    // ESTILOS PARA LOS MARCADORES DEL MAPA
    markerSize: 120,
    markerColorOpacity: 0.8,
    markerStrokeColorOpacity: 0.5,
    pixelRatio: 2, // 1-bigger -> 2..3..4.. smaller
  };

  pointsMapa: any[] = [];
  pointsMapaAdicional: any[] = [];
  pointsToRender: any[] = [];
  pointsByColor: any = {};

  /* SORT BY DEFAULT */
  sortColumn: SortColumn = 'IndInfAmb';
  sortDirection: SortDirection = 'desc';
  defSort: SortEvent = {
    column: this.sortColumn,
    direction: this.sortDirection,
  };

  isAllowed: boolean = false;
  message: string;

  /* PARA CONTROLAR EL RECAPTCHA */

  // PARA ETIQUETAS NUEVOS CAMPOS
  indicadoresNew: any;

  // Mapa mobile control
  mapaMobile: boolean = false;

  //Para control del keypress
  prevSearchHttp: any;
  listSearchEmpresa: any[] = [];
  selectedSearchEmpresa: any;

  searching = false;
  searchFailed = false;

  constructor(
    public _dataEmpresaService: DataEmpresaService,
    public _mapService: MapDataService,
    public _alertService: AlertService,
    public _filterService: FilterService,
    public route: ActivatedRoute,
    public router: Router,
    public _usuarioService: UsuarioService,
    public _shareSocialMedia: ShareSocialMediaService,
    public _captchaMessageService: CaptchaMessageService,
    private _modalService: NgbModal,
    private renderer: Renderer2
  ) {
    this._dataEmpresaService.validateUserPublic();

    // SI ES UN USUARIO REGISTRADO
    if (this._usuarioService.perfilUsuario) {
      // PARA EVALUAR SU MEMBRESÍA
      this.checkUsuario();
    } else {
      // SI ES USUARIO SIN REGISTRO
      // PARA USUARIOS FREE - MODAL REGISTRO
      this._alertService
        .alertConfirmWithCustomImage(
          '¡BIENVENID@!',
          'Estás a un solo clic de registrarte y obtener una membresía totalmente gratis. Accede a nuestras bases de datos y encuentra la información que buscas en un solo lugar.',
          'Registrarse',
          'Ahora no',
          '/assets/icons/mano-click-deep-data.png',
          100,
          134
        )
        .then((resp) => {
          if (resp.value) {
            this.router.navigate(['/registro']);
          } else {
            this.isAllowed = true;
            this.getHeadersForFilter();
            this.getParamsFromAnotherView(this.route);
            this.getPointsForMap();
            this.getNewFeautures();
            this.onSort(this.defSort, true);
          }
        });
    }

    if (screen.width <= 991) {
      this.mapaMobile = true;

      this.renderer.listen('window', 'click', (e: Event) => {
        if (
          e.target !== this.toggleButton?.nativeElement &&
          e.target !== this.menu?.nativeElement
        ) {
          this.showMobileOrderButtons = false;
        }
      });
    }
  }

  busquedaMasivaRedirect() {
    // DESCARGAR SOLO PARA USUARIOS PREMIUM
    if (this._usuarioService.isPremium()) {
      this.router.navigate(['/busqueda-masiva']);
    } else {
      this.callAlertPlan();
    }
  }

  // PARA EL SEARCH EMPRESA
  search: OperatorFunction<string, readonly { name }[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this._dataEmpresaService.getEmpresasInput(term, 6).pipe(
          tap(() => (this.searchFailed = false)),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.searching = false))
    );
  formatMatches = (value: any) => value.NombreEmpresa || '';

  selectedItem(value) {
    this.organizacion = value.item.NombreEmpresa;
  }

  selectedItemMobile(value) {
    this.organizacion = value.item.NombreEmpresa;
    this.realizarBusqueda();
  }

  selectOrdenamiento(indicador: number) {
    this.selectedOrdenamiento = this.cboOrdenamiento[indicador];
    this.changeSortMobile();
  }

  changeSortMobile(defaultDirection: boolean = false) {
    // Obteniendo la direccion
    let column = '';
    let direction = this.selectedOrdenamiento.value == 1 ? 'asc' : 'desc';
    if (defaultDirection) {
      this.selectedOrdenamiento = this.cboOrdenamiento[1];
      direction = 'desc';
    }

    switch (this.selectedIndicador.value) {
      case '1':
        //Infracciones
        column = 'IndInfAmb';
        break;
      case '2':
        //Multas perdonadas
        column = '';
        break;
      case '3':
        column = 'multas_laborales_judicial';
        break;
      case '4':
        //Derrames
        column = 'derramesacc';
        break;
      case '5':
        //Accidentes
        column = 'accidentesacc';
        break;
      case '6':
        //NroProyectos
        column = 'nroproyectosacc';
        break;
      case '7':
        //PermisosAgua
        column = 'permisosaguaacc';
        break;
      default:
        break;
    }

    if (this.oldSort) {
      delete this.filtros[this.oldSort];
    }
    this.oldSort = column;
    this.filtros[this.oldSort] = direction;

    this.getEmpresas();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    if (this.isAllowed) {
      this.onSort(this.defSort, true);
    }
  }

  checkUsuario() {
    if (this._usuarioService.registeredFromGoogle) {
      this._alertService
        .alertConfirm(
          '¡Gracias por registrarte!',
          `¡Gracias por registrarte, ${this._usuarioService.perfilUsuario?.nombres}! Ahora cuentas con nuestra Membresía Gratuita. Para acceder a más funcionalidades, considera adquirir nuestra Membresía Premium.`,
          'Ver membresías',
          'Aceptar'
        )
        .then((resp) => {
          if (resp.value) {
            this._usuarioService.registeredFromGoogle = false;
            this.router.navigate(['/planes']);
          } else {
            this._usuarioService.registeredFromGoogle = false;
            this.isAllowed = true;
            this.getHeadersForFilter();
            this.getParamsFromAnotherView(this.route);
            this.getPointsForMap();
            this.getNewFeautures();
            this.onSort(this.defSort, true);
          }
        });
    } else {
      this.getHeadersForFilter();
      this.getParamsFromAnotherView(this.route);
      this.getPointsForMap();
      this.getNewFeautures();
      this.onSort(this.defSort, true);
    }
  }

  getParamsFromAnotherView(route: ActivatedRoute) {
    route.queryParams.subscribe((params) => {
      try {
        if (params['search']) {
          let toSearch = params['search'];
          const onlyNumbersPattern: RegExp = new RegExp('^[0-9]+$');
          let isOnlyNumbers = onlyNumbersPattern.test(toSearch);

          if (isOnlyNumbers) {
            this.filtros.ruc = toSearch;
          } else {
            this.filtros.nombreEmpresa = toSearch;
            this.organizacion = toSearch;
          }
        }

        this.filtros.tipoInfraccion = this.cboTipoInfraccion[1].value;
        // Para el mobile
        this.selectedTipoInfraccion = this.cboTipoInfraccion[1];
        this.selectedIndicador = this.cboIndicadores[0];
        this.selectedOrdenamiento = this.cboOrdenamiento[1];

        if (!this._filterService.isEmpty) {
          this.filtros = this._filterService.filtrosIndustrias;
          this._filterService.clearFiltrosIndustrias();
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  getHeadersForFilter() {
    this._dataEmpresaService.getHeadersForFilter().subscribe((resp) => {
      if (resp?.success) {
        this.cboRegiones = resp.headers.Region.sort();
        this.cboRegiones.unshift('TODOS');

        this.cboAnios = resp.headers.Agno.sort();
        this.cboAnios.reverse();
        this.cboAnios.unshift('TODOS');

        this.cboActividadEconomica = resp.headers.SectorEconomico.sort().filter(
          (item) => item.trim() !== ''
        );
        this.cboActividadEconomica.unshift('TODOS');

        // SI HAY FILTROS DE LA VISTA DETALLE EMPRESA
        if (this.filtros.region) {
          this.cboRegiones.forEach((region) => {
            if (region === this.filtros.region) {
              this.selectedRegion = region;
            }
          });
        } else {
          this.selectedRegion = this.cboRegiones[0];
        }

        if (this.filtros.agno) {
          this.cboAnios.forEach((agno) => {
            if (agno === this.filtros.agno) {
              this.selectedAnio = agno;
            }
          });
        } else {
          this.selectedAnio = this.cboAnios[0];
        }

        if (this.filtros.sectorEconomico) {
          this.cboActividadEconomica.forEach((sectorEconomico) => {
            if (sectorEconomico === this.filtros.sectorEconomico) {
              this.selectedActividad = sectorEconomico;
            }
          });
        } else {
          this.selectedActividad = this.cboActividadEconomica[0];
        }

        // PARA ORGANIZACIÓN
        if (this.filtros.nombreEmpresa) {
          this.organizacion = this.filtros.nombreEmpresa;
        } else {
          this.organizacion = '';
        }

        this.selectedTipoInfraccion = this.cboTipoInfraccion[1];
        this.filtros.tipoInfraccion = this.selectedTipoInfraccion.value;
      }
    });
  }

  limpiarFiltros() {
    this._modalService.dismissAll();

    this.selectedRegion = this.cboRegiones[0];
    this.selectedAnio = this.cboAnios[0];
    this.selectedActividad = this.cboActividadEconomica[0];
    this.selectedTipoInfraccion = this.cboTipoInfraccion[0];
    this.organizacion = '';

    this.filtros.ruc = '';
    this.filtros.region = '';
    this.filtros.sectorEconomico = '';
    this.filtros.nombreEmpresa = '';
    this.filtros.tipoInfraccion = '';
    this.filtros.agno = '';
    this.filtros.page = 1;

    this.getEmpresas();
    this.updateMapWithFilters();
  }

  realizarBusqueda() {
    this.filtros.ruc = '';
    this._modalService.dismissAll();

    if (this.selectedRegion && this.selectedRegion != 'TODOS') {
      this.filtros.region = this.selectedRegion;
    } else {
      this.filtros.region = '';
    }

    if (this.selectedAnio && this.selectedAnio != 'TODOS') {
      this.filtros.agno = this.selectedAnio;
    } else {
      this.filtros.agno = '';
    }

    if (this.selectedActividad && this.selectedActividad != 'TODOS') {
      this.filtros.sectorEconomico = this.selectedActividad.trim();
    } else {
      this.filtros.sectorEconomico = '';
    }

    if (this.organizacion && this.organizacion != '') {
      let org: string = this.organizacion?.NombreEmpresa;
      this.filtros.nombreEmpresa = org?.trim().replace(/\s\s+/g, ' ');
    } else {
      this.filtros.nombreEmpresa = '';
    }

    if (this.filtros['sort[NombreEmpresa]']) {
      delete this.filtros['sort[NombreEmpresa]'];
    }
    if (this.filtros['sort[IndInfLab]']) {
      delete this.filtros['sort[IndInfLab]'];
    }
    if (this.filtros['sort[IndInfAmb]']) {
      delete this.filtros['sort[IndInfAmb]'];
    }
    if (this.filtros['sort[IndInfLab]']) {
      delete this.filtros['sort[IndInfLab]'];
    }
    if (this.filtros['sort[IndInfAmb]']) {
      delete this.filtros['sort[IndInfAmb]'];
    }

    this.getEmpresas();
    this.updateMapWithFilters();
  }

  formatdatanumber(data: number = 0) {
    const numberFormat1 = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: 2,
    });
    return numberFormat1.format(data);
  }

  getEmpresas() {
    this._alertService.alertLoading('Cargando información ...');
    this._dataEmpresaService
      .getEmpresasByFiltros(this.filtros)
      .subscribe((resp) => {
        if (resp?.success) {
          this.totalRegistrosEmpresas = +resp?.registros;

          this.empresas = resp.empresas;
          this.empresasPaginated = this.empresas;

          this.collectionSize = resp.registros;

          this.totalReactiva = resp.meta?.totalReactiva;
          this.totalCovid = resp.meta?.totalCovid;
          this.notaTotalCovid = resp.meta?.notaTotalCovid;
          this.totalProyectos = resp.meta?.totalNroProyectosAcc;
          this.totalDerrames = resp.meta?.totalDerramesAcc;
        }
        this._alertService.closeAlert();
      });
  }

  filterEmpresas(event: any) {
    let empresaSearch = event.trim();

    if (this.prevSearchHttp) {
      this.prevSearchHttp.unsubscribe();
    }

    this.prevSearchHttp = this._dataEmpresaService
      .getEmpresasInput(empresaSearch, 5)
      .subscribe((resp) => {
        if (resp.empresas) {
          this.listSearchEmpresa = resp.empresas;
        }
      });
  }

  /* PAGINACION FRONTEND + BACK */
  refreshEmpresasPagination() {
    this.filtros.page = this.page;
    this.getEmpresas();
  }

  cambiarVista(e) {
    if (e) {
      this.verCifras = true;
    } else {
      this.verCifras = false;
    }
  }

  // PARA LOS MAPAS
  iniciarMapa(points) {
    this.mapa = this._mapService.standar_initNewMap(this.mapOptions);

    this.mapa.on('load', () => {
      this._mapService.standar_mapWithLinesHover(this.mapa, this.mapOptions);

      for (let i = 0; i < points.length; i++) {
        this._mapService.standar_createMarkerNew(
          this.mapa,
          this.mapOptions,
          points[i]
        );
      }
    });
  }

  getPointsForMap(requestFromCbo: boolean = false) {
    if (requestFromCbo) {
      this._alertService.alertLoading('Cargando mapa');
      this.filtros.tipoInfraccion = this.selectedTipoInfraccion.value;
    }

    const infraccionesMapa$ = this._dataEmpresaService.getInfoForMap(
      this.filtros
    );
    const adicionalMapa$ = this._dataEmpresaService.getInfoAdicionalForMap(
      this.filtros
    );
    const allrequests = forkJoin(infraccionesMapa$, adicionalMapa$);

    allrequests.subscribe(
      (results) => {
        this.pointsMapa = results[0].empresas;
        this.pointsMapaAdicional = results[1].empresas;

        // PARSING DATA TO MAP
        this.pointsByColor = {};

        this.pointsByColor.arrayPointsLaborales = this.pointsMapa.filter(
          (x) => x.TipoInfraccion === 'laboral'
        );
        // this.pointsByColor.arrayPointsAccidenteLaborales = this.pointsMapa.filter(x => x.TipoInfraccion === 'acc');
        this.pointsByColor.arrayPointsAmbientales = this.pointsMapa.filter(
          (x) => x.TipoInfraccion === 'ambiental'
        );
        this.pointsByColor.arrayPointsPasivos = this.pointsMapaAdicional.filter(
          (x) => x.Tipo === 'Pasivos'
        );
        this.pointsByColor.arrayPointsProyectos =
          this.pointsMapaAdicional.filter((x) => x.Tipo === 'Proyectos');
        this.pointsByColor.arrayPointsAccidentes =
          this.pointsMapaAdicional.filter((x) => x.Tipo === 'Accidentes');
        this.pointsByColor.arrayPointsDerrames =
          this.pointsMapaAdicional.filter((x) => x.Tipo === 'Derrames');
        this.pointsByColor.arrayPointsPermisos =
          this.pointsMapaAdicional.filter((x) => x.Tipo === 'Permisos de agua');

        this.iniciarMapaCluster(this.pointsByColor);

        // CERRANDO MODAL
        if (requestFromCbo) {
          this._alertService.closeAlert();
        }
      }
      // error => {
      //   if(error.status === 429){
      //     this.captchaMessageService()
      //   }
      // }
    );
  }

  updateMapWithFilters() {
    if (this.mapa) {
      this.mapa.remove();
      this.getPointsForMap();
    }
  }

  // PARA EL MAPA CLUSTER
  iniciarMapaCluster(pointsByColor) {
    (this.mapOptions.theme = this._mapService.mapThemes.LIGHT_THEME),
      (this.mapa = this._mapService.cluster_initNewMap(this.mapOptions));

    this.mapa.on('load', () => {
      // DELIEANDO MAPA DEL PERÚ
      this._mapService.standar_mapWithLinesHover(this.mapa, this.mapOptions);

      // INFRACCIONES LABORALES
      let arrayLaborales = this._mapService.formatData(
        pointsByColor.arrayPointsLaborales,
        'arrayPointsLaborales',
        1
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayLaborales,
        'arrayPointsLaborales',
        colors.laboral,
        colors.white
      );

      // ACCIDENTES LABORALES - NO SE MUESTRA
      // let arrayAccidentesLaborales = this._mapService.formatData(pointsByColor.arrayAccidentesLaborales, 'arrayAccidentesLaborales');
      // this._mapService.cluster_loadMap(this.mapa, arrayAccidentesLaborales, 'arrayAccidentesLaborales', colors.accidentelab, colors.white);

      // INFRACCIONES AMBIENTALES
      let arrayAmbientales = this._mapService.formatData(
        pointsByColor.arrayPointsAmbientales,
        'arrayPointsAmbientales',
        1
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayAmbientales,
        'arrayPointsAmbientales',
        colors.ambiental,
        colors.white
      );

      // PASIVOS
      let arrayPasivos = this._mapService.formatData(
        pointsByColor.arrayPointsPasivos,
        'arrayPointsPasivos',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayPasivos,
        'arrayPointsPasivos',
        colors.pasivos,
        colors.white
      );

      // PROYECTOS
      let arrayProyectos = this._mapService.formatData(
        pointsByColor.arrayPointsProyectos,
        'arrayPointsProyectos',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayProyectos,
        'arrayPointsProyectos',
        colors.proyectos,
        colors.white
      );

      // ACCIDENTES
      let arrayAccidentes = this._mapService.formatData(
        pointsByColor.arrayPointsAccidentes,
        'arrayPointsAccidentes',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayAccidentes,
        'arrayPointsAccidentes',
        colors.accidentes,
        colors.white
      );

      // DERRAMES
      let arrayDerrames = this._mapService.formatData(
        pointsByColor.arrayPointsDerrames,
        'arrayPointsDerrames',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayDerrames,
        'arrayPointsDerrames',
        colors.derrames,
        colors.white
      );

      // PERMISOS
      let arrayPermisos = this._mapService.formatData(
        pointsByColor.arrayPointsPermisos,
        'arrayPointsPermisos',
        2
      );
      this._mapService.cluster_loadMapNotCluster(
        this.mapa,
        arrayPermisos,
        'arrayPointsPermisos',
        colors.permisos,
        colors.white
      );
    });
  }

  // PARA DETALLE DE EMPRESA
  verDetalleEmpresa(empresa) {
    if (this._usuarioService.isWithoutRegister()) {
      this._alertService
        .alertConfirm(
          'Regístrate',
          'Regístrate para obtener una Membresía Gratuita y accede a todas las funcionalidades básicas.',
          'Registrarse',
          'Ahora no'
        )
        .then((resp) => {
          if (resp.value) {
            this.router.navigate(['/registro']);
          }
        });
    } else {
      this._alertService.alertLoading(
        `Cargando información: ${empresa.NombreEmpresa.toUpperCase()}`
      );

      let navigationExtras: NavigationExtras = {};
      if (empresa) {
        navigationExtras = {
          queryParams: {
            ruc: empresa.Ruc,
          },
          skipLocationChange: false,
        };
      }

      this.router.navigate(['/detalle-empresa'], navigationExtras);
    }
  }

  // PARA COMPARTIR EN REDES SOCIALES
  shareFacebook() {
    this._shareSocialMedia.shareFacebook('https://deepdata.convoca.pe/');
    // this._shareSocialMedia.shareFacebook(window.location.href);
  }

  shareTwitter() {
    this._shareSocialMedia.shareTwitter('https://deepdata.convoca.pe/');
    // this._shareSocialMedia.shareTwitter(window.location.href);
  }

  shareLinkedin() {
    this._shareSocialMedia.shareLinkedin(window.location.href);
  }

  // FOR SORT TABLE
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  onSort({ column, direction }: SortEvent, onload = false) {
    if (onload) {
      column = 'IndInfAmb';
      direction = 'desc';
    } else {
      // resetting other headers
      this.headers.forEach((header) => {
        if (header.sortable !== column) {
          header.direction = '';
        }
      });
    }

    // sorting countries
    if (direction === '' || column === '') {
      if (this.oldSort) {
        delete this.filtros[this.oldSort];
      }
      this.getEmpresas();
    } else {
      if (this.oldSort) {
        delete this.filtros[this.oldSort];
      }
      this.oldSort = column;
      // this.oldSort = `sort[${column}]`;

      this.filtros[this.oldSort] = direction;

      this.getEmpresas();
    }
  }

  // PARA DESCARGAR DE ARCHIVO
  descargarResultados() {
    // DESCARGAR SOLO PARA USUARIOS PREMIUM
    if (this._usuarioService.isWithoutRegister()) {
      this._alertService
        .alertConfirm(
          'Regístrate',
          'Regístrate para obtener una Membresía Gratuita y accede a todas las funcionalidades básicas.',
          'Registrarse',
          'Ahora no'
        )
        .then((resp) => {
          if (resp.value) {
            this.router.navigate(['/registro']);
          }
        });
    } else if (this._usuarioService.isPremium()) {
      this._dataEmpresaService
        .getEmpresasByFiltrosDescargar(this.filtros)
        .subscribe((result) => {
          this.exportExelFile(result, 'resultado-busqueda');
        });
    } else {
      this.callAlertPlan();
    }
  }

  callAlertPlan() {
    this._alertService
      .alertConfirm(
        'Descubre más funcionalidades',
        'Para acceder a más funcionalidades, considera adquirir nuestra Membresía Premium.',
        'Ver membresías',
        'Aceptar'
      )
      .then((resp) => {
        if (resp.value) {
          this.router.navigate(['/planes']);
        }
      });
  }

  exportExelFile(data, filename) {
    const blobValue = new Blob([data['body']], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blobValue, filename + '.xlsx');
  }

  // PARA OBTENER NUEVAS ACTUALIZACIONES
  getNewFeautures() {
    this._dataEmpresaService.getNewFeautures().subscribe((resp) => {
      if (resp.success) {
        this.indicadoresNew = resp.indicadores;
        this.indicadoresNew = this.parseDataIndicadores(resp.indicadores);
      }
    });
  }

  parseDataIndicadores(indicadores) {
    let response: any = {};

    for (let i = 0; i < indicadores.length; i++) {
      response[indicadores[i].nombre_indicador] = indicadores[i].estado;
    }

    return response;
  }

  openMobileFilterModal(content) {
    this._modalService.open(content, { size: 'lg', centered: true });
  }

  showOrderButtons() {
    this.showMobileOrderButtons = !this.showMobileOrderButtons;
  }

  selectTab(indicador) {
    this.selectedIndicador = indicador;
    this.changeSortMobile();
  }
}
