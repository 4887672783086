import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ShareSocialMediaService } from '../../services/util/share-social-media.service';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/util/alert.service';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  @ViewChild('navbarToggler') navbarToggler: ElementRef;
  toggler = false;
  usuarioRegistrado: string = 'Ingresa';

  constructor (public authService: AuthService,
                public _usuarioService: UsuarioService,
               private _shareSocialMedia: ShareSocialMediaService,
               private _alertService: AlertService,
               public router: Router,
               ) { }

  ngOnInit(): void {

    // this.usuarioRegistrado =
    if (this.authService.token) {
      this.usuarioRegistrado = this.authService.usuario;
    }
    this.navbarToggler
  }

  // PARA COMPARTIR EN REDES SOCIALES
  shareFacebook() {
    this._shareSocialMedia.shareFacebook('https://deepdata.convoca.pe/');
  }

  shareTwitter(){
    this._shareSocialMedia.shareTwitter('https://deepdata.convoca.pe/');
  }

  shareLinkedin(){
    this._shareSocialMedia.shareLinkedin('https://deepdata.convoca.pe/');
  }

  // PARA MANEJO DE LA INFORMACIÓN DEL USUARIO
  cerrarSesion(){
    
    this._alertService.alertConfirm('¿Seguro que desea cerrar la sesión actual?','','Sí, cerrar','Cancelar').then(
      resp => {
        if(resp.value){
          this.authService.logout();
          this.router.navigate(["/ingreso"]);
        }
      }
    );

  }
  
}
