import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../../services/usuario.service';
import { AuthService } from '../../services/auth.service';
import { Router, NavigationExtras } from '@angular/router';
import { AlertService } from '../../services/util/alert.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  empresaSearch:string = '';
  srcLeft:string = '../../../assets/buttons/buttons_circle_active_left.png';
  srcRight:string = '../../../assets/buttons/buttons_circle_right_active.png';

  constructor(
        public _usuarioService: UsuarioService,
        public _authService: AuthService,
        private _alertService: AlertService,
        public router: Router,
  ) { }

  ngOnInit(): void {
  }

  verIndustrias(){

    let navigationExtras: NavigationExtras = {};
    
    if(this.empresaSearch){
      navigationExtras = {
        queryParams: {
            "search": this.empresaSearch
        },
        skipLocationChange: false
      }; 
    }

    this.router.navigate(["/industrias-extractivas"], navigationExtras);
  }

  verBusquedaMasiva(){
    this.router.navigate(["/busqueda-masiva"]);    
  }
  
  cerrarSesion(){
    
    this._alertService.alertConfirm('¿Seguro que desea cerrar la sesión actual?','','Sí, cerrar','Cancelar').then(
      resp => {
        if(resp.value){
          this._authService.logout();
          this.router.navigate(["/ingreso"]);
        }
      }
    );

  }

  // PARA FLECHAS DINÁMICAS
  hover(id) {
      if(id === "nameLeft"){
          var img = document.getElementById("nameLeft");
          img.setAttribute('src', '../../../assets/buttons/buttons_circle_hover_left.png');
      }else{
          var img = document.getElementById("nameRight");
          img.setAttribute('src', '../../../assets/buttons_circle_right_hover.png');
      }
  }
  
  unhover(id) {
      if(id === "nameLeft"){
          var img = document.getElementById("nameLeft");
          img.setAttribute('src', '../../../assets/buttons_circle_active_left.png');
      }else{
          var img = document.getElementById("nameRight");
          img.setAttribute('src', '../../../assets/buttons_circle_right_active.png');
      }
  }

}
