import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

const base_url = environment.base_url + '/ref-data';

@Injectable({
  providedIn: 'root',
})
export class DataReferenciaService {
  constructor(private http: HttpClient) {}

  getPaises() {
    return this.http.get(`${base_url}/paises`).pipe(
      tap((resp: any) => {
        return resp;
      }),
      catchError((error) => of(null))
    );
  }

  getArticulos() {
    return this.http.get(`${base_url}/articulos`).pipe(
      tap((resp: any) => {
        return resp;
      }),
      catchError((error) => of(null))
    );
  }
}
