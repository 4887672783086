import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';

import { Usuario } from '../models/usuario.model';
import { AlertService } from './util/alert.service';

const base_url = environment.base_url + '/usuario';
const base_url_google = environment.base_url + '/auth/login-google';
@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  perfilUsuario: any;
  tokenFromLogin: string;
  public registeredFromGoogle: boolean = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _alertService: AlertService
  ) {
    this.cargarPerfil();
  }

  registrarUsuario(usuario: Usuario) {
    return this.http.post(`${base_url}/registrar`, usuario).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        (error) => {
          this.manejarError(error);
        }
      )
    );
  }
  actualizarUsuario(nombres: string, celular: string, organizacion: string) {
    this.getToken();

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.tokenFromLogin,
    });

    return this.http
      .post(
        `${base_url}/perfilUpdate`,
        { nombres, celular, organizacion },
        { headers: headers }
      )
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            this.manejarError(error);
          }
        )
      );
  }
  registroGoogle(data) {
    return this.http.post(base_url_google, data).pipe(
      tap(
        (resp: any) => {
          console.log(resp);
          return resp;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
  confirmarCorreo(token: string) {
    return this.http
      .post(`${base_url}/confirmar-correo`, { token: token })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            this.manejarError(error, '/');
          }
        )
      );
  }

  cargarPerfil() {
    if (localStorage.getItem('perfil')) {
      this.perfilUsuario = JSON.parse(localStorage.getItem('perfil'));
    } else {
      this.perfilUsuario = null;
    }
  }

  getToken() {
    if (localStorage.getItem('token')) {
      this.tokenFromLogin = localStorage.getItem('token');
    } else {
      this.tokenFromLogin = '';
    }
  }

  /* PERFIL */
  getUsuarioPerfil() {
    this.getToken();

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.tokenFromLogin,
    });

    return this.http.get(`${base_url}/perfil`, { headers: headers }).pipe(
      tap(
        (resp: any) => {
          if (resp.success) {
            // this.removeStorage();
            this.perfilUsuario = resp.perfil;
            this.setStorage(this.perfilUsuario);
          }
          return resp;
        },
        (error) => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
            // return throwError(error);
          }
        }
      )
    );
  }

  private setStorage(perfil: any) {
    localStorage.setItem('perfil', JSON.stringify(perfil));
  }

  public removeStorage() {
    this.perfilUsuario = null;
    localStorage.removeItem('perfil');
  }

  /* RECUPERACIÓN DE CONTRASEÑAS */
  recuperarPassword(email: string) {
    return this.http
      .post(`${base_url}/recuperar-password`, { email: email })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            this.manejarError(error);
          }
        )
      );
  }

  cambiarPassword(token: string, nuevaPassword: string) {
    return this.http
      .post(`${base_url}/cambiar-password`, {
        token: token,
        nuevaPassword: nuevaPassword,
      })
      .pipe(
        tap(
          (resp: any) => {
            return resp;
          },
          (error) => {
            this.manejarError(error);
          }
        )
      );
  }

  manejarError(error: HttpErrorResponse, urlRedirect: string = '') {
    let titulo = '';
    let subtitulo = '';

    switch (error.status) {
      case 400:
        if (error.error.code === 'ALREADY_REGISTERED') {
          titulo = 'El usuario ya fue registrado';
          subtitulo = 'Intente ingresando otro email';
        } else if (error.error.code === 'BAD_REQUEST') {
          titulo = error.error.errors[0].msg;
          subtitulo = '';
        } else if (error.error.code === 'INVALID_TOKEN') {
          titulo = 'Ocurrió un problema';
          subtitulo = 'El token enviado no es válido';
        } else {
          titulo = 'Ups';
          subtitulo = 'Ocurrió un problema. Vuelve a intentar.';
        }

        this._alertService
          .alertError(titulo, subtitulo, 'Aceptar')
          .then((resp) => {
            if (resp.value) {
              if (urlRedirect) {
                this.router.navigate([urlRedirect]);
              }
            }
          });

        break;

      case 401:
        this._alertService
          .alertError(
            'Su sesión ha expirado',
            'Por favor vuelva a ingresar',
            'Ingresar'
          )
          .then((resp) => {
            if (resp.value) {
              // console.log('resp-alert',resp);
              this.router.navigate(['/ingreso']);
            }
          });
        break;
      default:
        this._alertService.alertError(
          'Ups',
          'Ocurrió un problema. Vuelve a intentar.'
        );
        break;
    }

    return throwError(error);
  }

  isPremium() {
    return this.perfilUsuario?.codigo_membresia === 'PREMIUM';
  }
  isFree() {
    return this.perfilUsuario?.codigo_membresia === 'FREE';
  }
  isWithoutRegister() {
    return !this.perfilUsuario?.codigo_membresia;
  }
}
