<!--Main Start-->
<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">

  <!-- Formulario de ingreso Start-->
  <div class="jpg-haslayout">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-10 col-lg-6">
        <div class="row card p-4">
          <div class="col-12 text-center pt-2">
            <h3>Formulario de ingreso</h3>
          </div>
          <div class="col-12 pt-2">
            <form [formGroup]="formaLogin" (ngSubmit)="validarLogin()" class="wt-formtheme wt-formregister">
              <div class="form-group">
                <label for="email" class="cols-sm-2 control-label">Email</label>
                <div class="cols-sm-10">
                  <input type="text" class="form-control" formControlName="email" name="email" id="email0"
                    placeholder="Ingresar Email" />
                </div>
              </div>

              <div class="form-group">
                <label for="password" class="cols-sm-2 control-label">Contraseña</label>
                <div class="cols-sm-10">
                  <input type="password" class="form-control" formControlName="password" name="password" id="password"
                    placeholder="Ingresar Contraseña" />
                </div>
              </div>

              <div class="form-group" *ngIf="!flagFormaValida">
                <span class="text-danger">{{ mensajeError }}</span>
              </div>

              <div class="form-group">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="customCheck" name="recordarCuenta" formControlName="recordarCuenta" value="recordarCuenta">
                  <label class="custom-control-label" for="customCheck">Recordar mi cuenta.</label>
                </div>
              </div>

              <!-- <div class="form-group d-none d-lg-block"> -->
              <div class="form-group">
                <button type="submit" class="btn jpg-btn">Ingresar</button>
              </div>
              
              <!-- <div class="form-group d-none d-lg-block"> -->
              <div class="form-group">
                <button type="button" class="btn btn-social-icon google" (click)="signInWithGoogle()"><span
                    class="fa fa-google"></span>
                  Ingresa con Gmail
                </button>
              </div>
            </form>
          </div>
          <div class="jpg-registerformfooter">
            <span><a [routerLink]="['/password']">Olvidé mi contraseña</a></span>
            <span>¿No tienes cuenta? <a [routerLink]='["/registro"]'>Regístrate</a></span>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Formulario de ingreso End-->

</main>
<!--Main End--> 