<div class="container reportajes">
  <div class="row">
    <div class="col-sm-12 col-md-6 mb-4" *ngFor="let articulo of articulos.slice((page-1) * pageSize, ((page-1) * pageSize) + pageSize)">
      <div class="col-sm-12 pt-5 pb-3"></div>
      <img [src]="articulo.link_imagen" class="img-fluid w-100 h-50" alt="..." />
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            <a class="" [href]="articulo.link_articulo" target="_blank">
              {{articulo.titulo}}
            </a>
          </h5>
          <p class="card-text text-justify">
            {{articulo.descripcion}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12 p-0 mt-4" *ngIf="articulos.length > 0">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [collectionSize]="articulos.length"
        [(page)]="page"
        [pageSize]="pageSize"
        [ellipses]="false"
        [maxSize]="3"
        [boundaryLinks]="true"
        [rotate]="true"
        (pageChange)="refreshArticulosPagination()"
      >
        <ng-template ngbPaginationFirst>Primero</ng-template>
        <ng-template ngbPaginationLast>Último</ng-template>
        <ng-template ngbPaginationPrevious><</ng-template>
        <ng-template ngbPaginationNext>></ng-template>
        <ng-template ngbPaginationEllipsis>...</ng-template>
        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>