<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor" style="background-color: white; height: 90vh;">
    <div class="jpg-haslayout">
  
      <!-- Busqeuda de empresas Listing Start-->
      <div class="jpg-haslayout">
        <div class="container-fluid">
        </div>
      </div>
      <!-- Busqeuda de empresas Listing End-->
  
    </div>
  </main>