import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// MODULES
import { ComponentsModule } from '../components/components.module';
import { SharedModule } from '../shared/shared.module';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { GoogleLoginProvider, SocialAuthServiceConfig, SocialAuthService } from 'angularx-social-login';
import { NgSelectModule } from '@ng-select/ng-select';

// ENVIROMENT VARIABLE
import { environment } from '../../environments/environment';

// COMPONENTES
import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { RegistroComponent } from './registro/registro.component';
import { IngresoComponent } from './ingreso/ingreso.component';
import { ConfirmarCuentaComponent } from './confirmar-cuenta/confirmar-cuenta.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CuentaComponent } from './cuenta/cuenta.component';
import { PlanesComponent } from './planes/planes.component';
import { PagoResultComponent } from './pagos/pago-result/pago-result.component';
import { OnlyDesktopComponent } from './only-desktop/only-desktop.component';

import { IndustriasExtractivasComponent, NgbdSortableHeader } from './search/industrias-extractivas/industrias-extractivas.component';
import { DetalleEmpresaComponent } from './search/detalle-empresa/detalle-empresa.component';
import { DetalleSemaforoComponent } from './search/detalle-semaforo/detalle-semaforo.component';
import { BusquedaMasivaComponent } from './search/busqueda-masiva/busqueda-masiva.component';
import { BusquedaMasivaDatosEncontradosComponent } from './search/busqueda-masiva-datos-encontrados/busqueda-masiva-datos-encontrados.component';
import { DetalleAdicionalComponent } from './search/detalle-adicional/detalle-adicional.component';

import { NosotrosComponent } from './nosotros/nosotros.component';
import { ReportajesComponent } from './reportajes/reportajes.component';
import { MetodologiasComponent } from './metodologias/metodologias.component';

@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    RegistroComponent,
    IngresoComponent,
    ConfirmarCuentaComponent,
    CuentaComponent,
    PlanesComponent,
    PagoResultComponent,
    DetalleAdicionalComponent,
    OnlyDesktopComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    IndustriasExtractivasComponent,
    NgbdSortableHeader,
    DetalleEmpresaComponent,
    DetalleSemaforoComponent,
    BusquedaMasivaComponent,
    BusquedaMasivaDatosEncontradosComponent,
    NosotrosComponent,
    ReportajesComponent,
    MetodologiasComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    SharedModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    NgSelectModule
  ],
  exports: [
    //IndustriasExtractivasComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcDEMoZAAAAAL1zYADrCQpJ0MBJ9GR115ElDjvr',
      } as RecaptchaSettings,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider( environment.googleLoginId, { scope: 'profile email' })
          }
        ]
      } as SocialAuthServiceConfig,
    },
    SocialAuthService
  ],
  bootstrap: [IndustriasExtractivasComponent]
})
export class PagesModule { }
