import { Component, OnInit } from '@angular/core';
import { DataReferenciaService } from 'src/app/services/data-referencia.service';

@Component({
  selector: 'app-reportajes',
  templateUrl: './reportajes.component.html',
  styleUrls: ['./reportajes.component.css'],
})
export class ReportajesComponent implements OnInit {
  articulos: any[];
  page = 1; 
  pageSize = 8;

  constructor(private _dataReferencia: DataReferenciaService) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getArticulos(); 
  }
  getArticulos() {
    this._dataReferencia.getArticulos().subscribe((resp) => {
      if (resp?.success) {
        this.articulos = resp.articulos;
      }
    });
  }
}
