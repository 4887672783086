
export class Usuario{
    
    email:string;
    nombres:string;
    apellidos:string;
    sexo:string;
    password:string;
    pais:string;
    profesion:string;
    organizacion:string;
    celular: string;
    recaptcha: any;
    
    constructor(){}

}

