<!--Filter Results Strart-->
<div class="d-none d-lg-block jpg-haslayout">
    <!-- <div class="jpg-title">
      <h2>Filtrar resultados:</h2>
    </div> -->
    <div class="">
      <div class="jpg-categoryslidercontent">
        <div class="row">
          <div class="col-12 col-md pb-3 pb-lg-0 d-flex align-items-center">
            <div class="jpg-cattitle" style="padding-left: 1rem; width: 100%;">
              <!-- <h3>Actividad económica</h3> -->
              <h2 style="margin: 0;
              font-size: 18px;
              font-weight: 400;
              line-height: 20px;">Filtrar resultados:</h2>
            </div>
          </div>
          <div class="col-12 col-md border pb-3 pb-lg-0">
            <figure>
              <i class="fa fa-industry"></i>
            </figure>
            <div class="jpg-cattitle">
              <h3>Actividad económica</h3>
              <div class="form-group mb-0">
                <span class="jpg-select">
                  <select id="cboActividad" name="cboActividad" [(ngModel)]="selectedActividad" (ngModelChange)="realizarBusqueda()">
                    <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                            {{ actividad }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md border pb-3 pb-lg-0">
            <figure>
              <i class="fa fa-building-o"></i>
            </figure>
            <div class="jpg-cattitle">
              <h3>Empresa</h3>
              <div class="form-group mb-0">
  
                <!-- <input type="text" placeholder="Empresa" id="organizacion" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()" (input)="filterEmpresas($event.target.value)"> -->
  
                <ng-template #rt let-r="result" let-t="term">
                  <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                </ng-template>
                
                <input id="typeahead-http-1" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa" [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)" (keydown.enter)="realizarBusqueda()" />
                <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
                <div class="invalid-feedback" *ngIf="searchFailed">No encontramos coincidencias</div>
  
              </div>
            </div>
          </div>
          <div class="col-12 col-md border pb-3 pb-lg-0">
            <figure>
              <i class="fa fa-map-marker"></i>
            </figure>
            <div class="jpg-cattitle">
              <h3>Región</h3>
              <div class="form-group mb-0">
                <span class="jpg-select">
                  <select id="cboRegion" name="cboRegion" [(ngModel)]="selectedRegion" (ngModelChange)="realizarBusqueda()">
                    <option *ngFor="let region of cboRegiones" [ngValue]="region">
                            {{ region }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md border pb-3 pb-lg-0">
            <figure>
              <i class="fa fa-calendar-o"></i>
            </figure>
            <div class="jpg-cattitle">
              <h3>Año</h3>
              <div class="form-group mb-0">
                <span class="jpg-select">
                  <select id="cboAnio" name="cboAnio" [(ngModel)]="selectedAnio" (ngModelChange)="realizarBusqueda()">
                    <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                            {{ anio }}
                    </option>
                  </select>
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md p-3 pt-lg-1 pb-lg-0">
            <div class="jpg-cattitle btn-limpiar">
              <button type="button" class="btn btn-dark text-uppercase font-weight-bold" (click)="limpiarFiltros()">Limpiar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
<!--Filter Results End-->


<!-- BANNER MOBILE -->
<div class="d-block d-lg-none container-fluid">
    <div class="row metodologias-hero">
        <div class="col d-block align-self-center metodologias-hero-text">
            <div class="hero-main-text">
                <h2 class="text-center text-white banner-mobile">Descubre a las empresas mineras y de hidrocarburos en el Perú</h2>
                <p class="text-center text-white">Inicio / Búsquedas / Empresas</p>
            </div>
        </div>
    </div>
</div>

<!-- FILTERS MOBILE -->
<div class="d-block d-lg-none container-fluid">
    <div class="row justify-content-md-center">
        <div class="col-xs-12 col-sm-12">
            <div class="row card p-4" style="background-color: #ececec">

                <div class="col-12 text-left p-0">
                    <h4>Realiza tu búsqueda:</h4>

                    <div class="form-group">
                        <label for="organizacion-mb" class="bold"><i class="fa fa-building-o"></i> Empresa</label>
                        <!-- <input type="text" class="w-100" placeholder="Empresa" id="organizacion-mb" name="organizacion" [(ngModel)]="organizacion" (keydown.enter)="realizarBusqueda()"> -->

                        <ng-template #rt let-r="result" let-t="term">
                            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
                        </ng-template>
                        
                        <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed" [(ngModel)]="organizacion" [ngbTypeahead]="search" placeholder="Buscar empresa" [resultFormatter]="formatMatches" [inputFormatter]="formatMatches" (selectItem)="selectedItem($event)" (keydown.enter)="realizarBusqueda()" />
                        <!-- <small *ngIf="searching" class="form-text text-muted">buscando...</small> -->
                        <div class="invalid-feedback" *ngIf="searchFailed">No encontramos coincidencias</div>

                    </div>
                    <div class="form-group">
                        <label for="organizacion-mb" class="bold"><i class="fa fa-industry"></i> Actividad
                            económica</label>
                        <select id="cboActividad" class="w-100" name="cboActividad" [(ngModel)]="selectedActividad"
                            (ngModelChange)="realizarBusqueda()">
                            <option *ngFor="let actividad of cboActividadEconomica" [ngValue]="actividad">
                                {{ actividad }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="organizacion-mb" class="bold"><i class="fa fa-map-marker"></i> Región</label>
                        <select id="cboRegion" class="w-100" name="cboRegion" [(ngModel)]="selectedRegion"
                            (ngModelChange)="realizarBusqueda()">
                            <option *ngFor="let region of cboRegiones" [ngValue]="region">
                                {{ region }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="organizacion-mb" class="bold"><i class="fa fa-calendar-o"></i> Año</label>
                        <select id="cboAnio" class="w-100" name="cboAnio" [(ngModel)]="selectedAnio"
                            (ngModelChange)="realizarBusqueda()">
                            <option *ngFor="let anio of cboAnios" [ngValue]="anio">
                                {{ anio }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group d-flex justify-content-between mt-2">
                        <button type="button" class="btn btn-dark text-uppercase w-100 mr-2"
                            (click)="limpiarFiltros()">Limpiar</button>
                        <!-- <button type="button" class="btn btn-danger text-uppercase w-100 ml-2"
                            [routerLink]='["/busqueda-masiva"]'>BÚSQUEDA MASIVA</button> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="empresa" class="d-block d-lg-none container-fluid card" style="background-color: #f9f8f8">

    <div class="row pt-4 pl-4 pr-4">
        <div class="col-8 text-left p-0 pr-2">
            <h5>{{ empresa.NombreEmpresa }}</h5>
            <span class="text-success font-weight-bold">
                <i class="fa fa-building"></i> {{ empresa.Estado }}
            </span>
            <span>
                RUC: {{ empresa.Ruc }}
            </span>
        </div>
        <div class="col-4 text-left p-0">
            <p class="mb-1">Inicio de actividades:</p>
            <span>{{ empresa.InicioActividades }}</span>
        </div>
    </div>

    <!-- <div class="row pt-2 pl-4 pr-2 pb-4">
        <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2">Empresa muy infractora
    </div> -->

    <div class="row p-4">
        <div class="col-12 text-left p-0">
            <h5>>> Indicadores</h5>
            <select class="w-100" id="cboIndicadores" name="cboIndicadores" [(ngModel)]="selectedIndicador">
                <option *ngFor="let indicador of cboIndicadores" [ngValue]="indicador">
                    {{ indicador.id }}
                </option>
            </select>
        </div>
    </div>

</div>

<!-- CARDS REPLACING TABLE -->

<!-- PROYECTOS -->
<div *ngIf="selectedIndicador.value === '1'">
    <div class="d-block d-lg-none" *ngIf="proyectos">

        <!-- MAIN -->
        <div *ngIf="proyectos.length > 0 && !detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <a class="text-dark d-block" href="javascript:void(0)">
                    <div class="row pt-3 pl-0 pr-0">
                        <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-2 mb-2">Unidad ambiental/Lote</p>
                            </div>
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-2 mb-2">Tipo de proyecto</p>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let a of proyectos" class="row p-0 div-card-row">
                        <div class="col-12 font-weight-bold d-flex align-items-center">
                            <div class="col-6 p-1 pr-3 aling-self-content">
                                <p class="mt-3 mb-3">{{ a.unidad_ambiental }}</p>
                            </div>
                            <div class="col-4 p-1 pr-3">
                                <p class="mt-3 mb-3">{{ a.tipo_proyecto }}</p>
                            </div>
                            <div class="col-2 p-1 pr-3 text-right">
                                <button style="background: none; color: #dc3545;" (click)="verDetalleRow(a)">
                                    <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    
        <!-- NO ROWS -->
        <div *ngIf="proyectos.length <= 0" class="mb-4">
            <div class="container-fluid card p-3" style="font-size: .8rem;">
                <p class="mb-0 text-center"><b>No se encontraron registros con los criterios ingresados.</b></p>
            </div>
        </div>

        <!-- PAGINATION -->
        <div *ngIf="proyectos.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
            <div class="d-flex justify-content-center">
                <ngb-pagination [collectionSize]="proyectosCollectionSize" [(page)]="proyectosPage" [pageSize]="proyectosPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(1)">
                    <ng-template ngbPaginationFirst>Primero</ng-template>
                    <ng-template ngbPaginationLast>Último</ng-template>
                    <ng-template ngbPaginationPrevious><</ng-template>
                    <ng-template ngbPaginationNext>></ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>


        <!-- DETALLE -->
        <div *ngIf="proyectos.length > 0 && detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <div class="row pt-3 pl-0 pr-0">
                    <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                        <div class="col-2 p-1 pr-3 text-left">
                            <button style="background: none; color: #dc3545;" (click)="volverListaDetalle()">
                                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-10 p-1 pr-3">
                            <div class="row">
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">Unidad ambiental/Lote</p>
                                </div>
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">Tipo de proyecto</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.unidad_ambiental }}</p>
                                </div>
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.tipo_proyecto }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Ubicación</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.region }}, {{ selectedRowDetail.provincia }}, {{ selectedRowDetail.distrito }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Fecha de suscripción del contrato</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.fec_autorizacion }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Año</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.agno }}</p>
                        </div>
                    </div>
                </div>
                <!-- <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Ubicación</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.region }}, {{ selectedRowDetail.provincia }}, {{ selectedRowDetail.distrito }}</p>
                        </div>
                    </div>
                </div> -->

            </div>
        </div>

        <!-- DESCARGAR -->
        <div class="container-fluid">
            <div class="row pr-4 pl-4 pb-4">
                <div *ngIf="_usuarioService.isPremium() && proyectos?.length > 0" class="col-12 col-sm-6 d-flex">
                    <a href="javascript:void(0);" (click)="descargarResultados(1)" class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2"> 
                    <i class="fa fa-download"></i> Descargar</a>
                </div>
                <div class="col-12 col-sm-6 align-self-center">
                    <a href="javascript:void(0);" (click)="verDetalleEmpresa()" class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
                </div>
            </div>
        </div>

    </div>
</div>


<!-- DERRAMES -->
<div *ngIf="selectedIndicador.value === '2'">
    <div class="d-block d-lg-none" *ngIf="derrames">

        <!-- MAIN -->
        <div *ngIf="derrames.length > 0 && !detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <a class="text-dark d-block" href="javascript:void(0)">
                    <div class="row pt-3 pl-0 pr-0">
                        <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                            <div class="col-4 p-1 pr-3">
                                <p class="mt-2 mb-2">Lote</p>
                            </div>
                            <div class="col-8 p-1 pr-3">
                                <p class="mt-2 mb-2">Descripción</p>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let a of derrames" class="row p-0 div-card-row">
                        <div class="col-12 font-weight-bold d-flex align-items-center">
                            <div class="col-4 p-1 pr-3 aling-self-content">
                                <p class="mt-3 mb-3">{{ a.lote }}</p>
                            </div>
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-3 mb-3">{{ a.descripcion }}</p>
                            </div>
                            <div class="col-2 p-1 pr-3 text-right">
                                <button style="background: none; color: #dc3545;" (click)="verDetalleRow(a)">
                                    <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    
        <!-- NO ROWS -->
        <div *ngIf="derrames.length <= 0" class="mb-4">
            <div class="container-fluid card p-3" style="font-size: .8rem;">
                <p class="mb-0 text-center"><b>No se encontraron registros con los criterios ingresados.</b></p>
            </div>
        </div>

        <!-- PAGINATION -->
        <div *ngIf="derrames.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
            <div class="d-flex justify-content-center">
                <ngb-pagination [collectionSize]="derramesCollectionSize" [(page)]="derramesPage" [pageSize]="derramesPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(2)">
                    <ng-template ngbPaginationFirst>Primero</ng-template>
                    <ng-template ngbPaginationLast>Último</ng-template>
                    <ng-template ngbPaginationPrevious><</ng-template>
                    <ng-template ngbPaginationNext>></ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>

        <!-- DETALLE -->
        <div *ngIf="derrames.length > 0 && detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <div class="row pt-3 pl-0 pr-0">
                    <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                        <div class="col-2 p-1 pr-3 text-left">
                            <button style="background: none; color: #dc3545;" (click)="volverListaDetalle()">
                                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-10 p-1 pr-3">
                            <div class="row">
                                <div class="col-5 p-1 pr-3">
                                    <p class="mt-2 mb-2">Lote</p>
                                </div>
                                <div class="col-7 p-1 pr-3">
                                    <p class="mt-2 mb-2">Descripción</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.lote }}</p>
                                </div>
                                <div class="col-7 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.descripcion }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Fecha del derrame</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.fec_derrame }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Año</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.agno }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Ubicacion</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.region }}, {{ selectedRowDetail.provincia }}, {{ selectedRowDetail.distrito }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- DESCARGAR -->
        <div class="container-fluid">
            <div class="row pr-4 pl-4 pb-4">
                <div *ngIf="_usuarioService.isPremium() && derrames?.length > 0" class="col-12 col-sm-6 d-flex">
                    <a href="javascript:void(0);" (click)="descargarResultados(2)" class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2"> 
                    <i class="fa fa-download"></i> Descargar</a>
                </div>
                <div class="col-12 col-sm-6 align-self-center">
                    <a href="javascript:void(0);" (click)="verDetalleEmpresa()" class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
                </div>
            </div>
        </div>
        
    </div>
</div>

<!-- PERMISOS DE AGUA -->
<div *ngIf="selectedIndicador.value === '3'">
    <div class="d-block d-lg-none" *ngIf="permisos">

        <!-- MAIN -->
        <div *ngIf="permisos.length > 0 && !detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <a class="text-dark d-block" href="javascript:void(0)">
                    <div class="row pt-3 pl-0 pr-0">
                        <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-2 mb-2">Volumen (m3)</p>
                            </div>
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-2 mb-2">Clase derecho</p>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let a of permisos" class="row p-0 div-card-row">
                        <div class="col-12 font-weight-bold d-flex align-items-center">
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-3 mb-3">{{ a.volumen_m3 }}</p>
                            </div>
                            <div class="col-4 p-1 pr-3 aling-self-content">
                                <p class="mt-3 mb-3">{{ a.clase_derecho }}</p>
                            </div>
                            <div class="col-2 p-1 pr-3 text-right">
                                <button style="background: none; color: #dc3545;" (click)="verDetalleRow(a)">
                                    <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    
        <!-- NO ROWS -->
        <div *ngIf="permisos.length <= 0" class="mb-4">
            <div class="container-fluid card p-3" style="font-size: .8rem;">
                <p class="mb-0 text-center"><b>No se encontraron registros con los criterios ingresados.</b></p>
            </div>
        </div>

        <!-- PAGINATION -->
        <div *ngIf="permisos.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
            <div class="d-flex justify-content-center">
                <ngb-pagination [collectionSize]="permisosCollectionSize" [(page)]="permisosPage" [pageSize]="permisosPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(4)">
                    <ng-template ngbPaginationFirst>Primero</ng-template>
                    <ng-template ngbPaginationLast>Último</ng-template>
                    <ng-template ngbPaginationPrevious><</ng-template>
                    <ng-template ngbPaginationNext>></ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>

        <!-- DETALLE -->
        <div *ngIf="permisos.length > 0 && detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <div class="row pt-3 pl-0 pr-0">
                    <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                        <div class="col-2 p-1 pr-3 text-left">
                            <button style="background: none; color: #dc3545;" (click)="volverListaDetalle()">
                                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-10 p-1 pr-3">
                            <div class="row">
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">Volumen (m3)</p>
                                </div>
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">Clase derecho</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.volumen_m3 }}</p>
                                </div>
                                <div class="col-6 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.clase_derecho }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Resolución</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.resolucion }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Fecha de resolución</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <a *ngIf="selectedRowDetail.url_resolucion && selectedRowDetail.url_resolucion != null" [href]="selectedRowDetail.url_resolucion" target="_blank">{{ selectedRowDetail.fec_resolucion }}</a>
                            <p *ngIf="!(selectedRowDetail.url_resolucion && selectedRowDetail.url_resolucion != null)" class="mt-3 mb-3">{{ selectedRowDetail.fec_resolucion }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Año</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.agno }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Tipo de uso</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.tipo_uso }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Ubicación</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.region }}, {{ selectedRowDetail.provincia }}, {{ selectedRowDetail.distrito }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- DESCARGAR -->
        <div class="container-fluid">
            <div class="row pr-4 pl-4 pb-4">
                <div *ngIf="_usuarioService.isPremium() && permisos?.length > 0" class="col-12 col-sm-6 d-flex">
                    <a href="javascript:void(0);" (click)="descargarResultados(3)" class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2"> 
                    <i class="fa fa-download"></i> Descargar</a>
                </div>
                <div class="col-12 col-sm-6 align-self-center">
                    <a href="javascript:void(0);" (click)="verDetalleEmpresa()" class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
                </div>
            </div>
        </div>
        
    </div>
</div>

<!-- ACCIDENTES -->
<div *ngIf="selectedIndicador.value === '4'">
    <div class="d-block d-lg-none" *ngIf="accidentes">

        <!-- MAIN -->
        <div *ngIf="accidentes.length > 0 && !detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <a class="text-dark d-block" href="javascript:void(0)">
                    <div class="row pt-3 pl-0 pr-0">
                        <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-2 mb-2">Lote</p>
                            </div>
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-2 mb-2">Nivel de gravedad</p>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let a of accidentes" class="row p-0 div-card-row">
                        <div class="col-12 font-weight-bold d-flex align-items-center">
                            <div class="col-6 p-1 pr-3">
                                <p class="mt-3 mb-3">{{ a.lote }}</p>
                            </div>
                            <div class="col-4 p-1 pr-3 aling-self-content">
                                <p class="mt-3 mb-3">{{ a.nivel_gravedad }}</p>
                            </div>
                            <div class="col-2 p-1 pr-3 text-right">
                                <button style="background: none; color: #dc3545;" (click)="verDetalleRow(a)">
                                    <i class="fa fa-arrow-right fa-lg" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    
        <!-- NO ROWS -->
        <div *ngIf="accidentes.length <= 0" class="mb-4">
            <div class="container-fluid card p-3" style="font-size: .8rem;">
                <p class="mb-0 text-center"><b>No se encontraron registros con los criterios ingresados.</b></p>
            </div>
        </div>

        <!-- PAGINATION -->
        <div *ngIf="accidentes.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
            <div class="d-flex justify-content-center">
                <ngb-pagination [collectionSize]="accidentesCollectionSize" [(page)]="accidentesPage" [pageSize]="accidentesPageSize" [ellipses]="false" [maxSize]="3" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(3)">
                    <ng-template ngbPaginationFirst>Primero</ng-template>
                    <ng-template ngbPaginationLast>Último</ng-template>
                    <ng-template ngbPaginationPrevious><</ng-template>
                    <ng-template ngbPaginationNext>></ng-template>
                    <ng-template ngbPaginationEllipsis>...</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>

        <!-- DETALLE -->
        <div *ngIf="accidentes.length > 0 && detailRow" class="border-bottom mb-4">
            <div class="container-fluid" style="font-size: .8rem;">

                <div class="row pt-3 pl-0 pr-0">
                    <div class="col-12 font-weight-bold d-flex align-items-center border custom-header-table">
                        <div class="col-2 p-1 pr-3 text-left">
                            <button style="background: none; color: #dc3545;" (click)="volverListaDetalle()">
                                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="col-10 p-1 pr-3">
                            <div class="row">
                                <div class="col-7 p-1 pr-3">
                                    <p class="mt-2 mb-2">Lote</p>
                                </div>
                                <div class="col-5 p-1 pr-3">
                                    <p class="mt-2 mb-2">Nivel de gravedad</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.lote }}</p>
                                </div>
                                <div class="col-5 p-1 pr-3">
                                    <p class="mt-2 mb-2">{{ selectedRowDetail.nivel_gravedad }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Fecha de accidente</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.fec_accidente }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Año</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.agno }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Ubicación</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.region }}, {{ selectedRowDetail.provincia }}, {{ selectedRowDetail.distrito }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Número de accidentados</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.nro_accidentados }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Responsable del trabajador</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.responsable_trabajador }}</p>
                        </div>
                    </div>
                </div>
                <div class="row p-0 div-card-row">
                    <div class="col-12 font-weight-bold d-flex align-items-center">
                        <div class="col-5 p-1 pr-3 aling-self-content">
                            <p class="mt-3 mb-3">Número de accidentes</p>
                        </div>
                        <div class="col-7 p-1 pr-3">
                            <p class="mt-3 mb-3">{{ selectedRowDetail.nro_accidentes }}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- DESCARGAR -->
        <div class="container-fluid">
            <div class="row pr-4 pl-4 pb-4">
                <div *ngIf="_usuarioService.isPremium() && accidentes?.length > 0" class="col-12 col-sm-6 d-flex">
                    <a href="javascript:void(0);" (click)="descargarResultados(4)" class="btn btn-dark text-uppercase font-weight-bold w-100 mb-2"> 
                    <i class="fa fa-download"></i> Descargar</a>
                </div>
                <div class="col-12 col-sm-6 align-self-center">
                    <a href="javascript:void(0);" (click)="verDetalleEmpresa()" class="btn btn-danger text-uppercase font-weight-bold w-100 mb-2">Regresar</a>
                </div>
            </div>
        </div>
        
    </div>
</div>

<!-- CARDS REPLACING TABLE -->

<!--Main Start-->
<div class="d-none d-lg-block">
    <main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
        <!-- Semaforo de empresa Start-->

        <div class="jpg-haslayout">
            <div class="container-fluid">

                <!--Company Details Start-->
                <div class="row mb-3">
                    <div class="col-xs-12 col-sm-12 col-md-12 float-left">
                        <div class="card border-0 jpg-bgwhite jpg-haslayout">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div *ngIf="empresa" class="col">
                                        <h2>{{ empresa.NombreEmpresa }}</h2>
                                        <ul class="jpg-userlisting-breadcrumb jpg-userlisting-breadcrumbvtwo">
                                            <li>
                                                <span class="text-success font-weight-bold">
                                                    <i class="fa fa-building"></i>{{ empresa.Estado }}</span>
                                            </li>
                                            <li><span>
                                                    <i class="fa fa-list-alt"></i>
                                                    RUC: {{ empresa.Ruc }}</span>
                                            </li>
                                            <li>
                                                <span><i class="fa fa-calendar-o"></i> INICIO DE
                                                    ACTIVIDADES: {{ empresa.InicioActividades }}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-md-auto align-self-center">
                                        <a href="javascript:void(0);" (click)="verDetalleEmpresa()"
                                            class="btn btn-danger text-uppercase font-weight-bold">Regresar</a>
                                    </div>

                                </div>
                                <div class="jpg-widgettitle mt-2 w-100"></div>

                                <!--Tabs infranctions Start-->

                                <ul class="jpg-navarticletab jpg-navarticletabvthree nav navbar-nav">
                                    <li class="nav-item mr-3">
                                        <a id="all-tab" [ngClass]="indicador == 1 ? 'active' : ''" data-toggle="tab"
                                            href="#tab01">Proyectos</a>
                                    </li>
                                    <li class="nav-item mr-3">
                                        <a id="business-tab" [ngClass]="indicador == 2 ? 'active' : ''" data-toggle="tab"
                                            href="#tab02">Derrames</a>
                                    </li>
                                    <li class="nav-item mr-3">
                                        <a id="trading-tab" [ngClass]="indicador == 4 ? 'active' : ''" data-toggle="tab"
                                            href="#tab03">Accidentes</a>
                                    </li>
                                    <li class="nav-item mr-3">
                                        <a id="permisos-tab" [ngClass]="indicador == 3 ? 'active' : ''" data-toggle="tab"
                                            href="#tab04">Permisos de agua</a>
                                    </li>
                                </ul>

                                <div class="tab-content jpg-haslayout jpg-haslayout-gray">

                                    <!--Proyectos-->
                                    <div class="jpg-contentarticle tab-pane fade"
                                        [ngClass]="indicador == 1 ? 'active show' : ''" id="tab01">

                                        <div *ngIf="_usuarioService.isPremium() && proyectos?.length > 0">
                                            <div class="col-md-12 text-right">
                                                <br>
                                                <a href="javascript:void(0);" (click)="descargarResultados(1)"
                                                    class="btn btn-dark text-uppercase font-weight-bold px-5"> <i
                                                        class="fa fa-download"></i> Descargar</a>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                                                <div class="col-12 col-sm-12 col-md-12 ">
                                                    <div class="table-responsive">
                                                        <table *ngIf="proyectos"
                                                            class="table table-striped jpg-tablecategories  tbl-nivel3">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Unidad ambiental/Lote</th>
                                                                    <th class="text-center">Tipo de proyecto</th>
                                                                    <th class="text-center">Fecha de suscripción del
                                                                        contrato</th>
                                                                    <th class="text-center">Año</th>
                                                                    <th class="text-center">Región</th>
                                                                    <th class="text-center">Provincia</th>
                                                                    <th class="text-center">Distrito</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="proyectos?.length > 0">
                                                                <tr *ngFor="let a of proyectos">
                                                                    <td class="text-left">{{ a.unidad_ambiental }}</td>
                                                                    <td class="text-left">{{ a.tipo_proyecto }}</td>
                                                                    <td class="text-left">{{ a.fec_autorizacion }}</td>
                                                                    <td class="text-left">{{ a.agno }}</td>
                                                                    <td class="text-left">{{ a.region }}</td>
                                                                    <td class="text-left">{{ a.provincia }}</td>
                                                                    <td class="text-left">{{ a.distrito }}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="proyectos?.length <= 0">
                                                                <tr>
                                                                    <td colspan="12">No se encontraron registros.</td>
                                                                </tr>
                                                            </tbody>

                                                            <div *ngIf="proyectos.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
                                                                <div class="d-flex justify-content-left">
                                                                    <ngb-pagination [collectionSize]="proyectosCollectionSize" [(page)]="proyectosPage" [pageSize]="proyectosPageSize" [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(1)">
                                                                        <ng-template ngbPaginationFirst>Primero</ng-template>
                                                                        <ng-template ngbPaginationLast>Último</ng-template>
                                                                        <ng-template ngbPaginationPrevious><</ng-template>
                                                                        <ng-template ngbPaginationNext>></ng-template>
                                                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                    </ngb-pagination>
                                                                </div>
                                                            </div>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#infraccionesambientales">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo
                                            de cumplimiento de normas
                                        </button> -->

                                    </div>

                                    <!--Derrames-->
                                    <div class="jpg-contentarticle tab-pane fade"
                                        [ngClass]="indicador == 2 ? 'active show' : ''" id="tab02">

                                        <div *ngIf="_usuarioService.isPremium() && derrames?.length > 0">
                                            <div class="col-md-12 text-right">
                                                <br>
                                                <a href="javascript:void(0);" (click)="descargarResultados(2)"
                                                    class="btn btn-dark text-uppercase font-weight-bold px-5"> <i
                                                        class="fa fa-download"></i> Descargar</a>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                                                <div class="col-12 col-sm-12 col-md-12 ">
                                                    <div class="table-responsive">
                                                        <table *ngIf="derrames"
                                                            class="table table-striped jpg-tablecategories">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Lote</th>
                                                                    <th class="text-center">Descripción</th>
                                                                    <th class="text-center">Fecha del derrame</th>
                                                                    <th class="text-center">Año</th>
                                                                    <th class="text-center">Región</th>
                                                                    <th class="text-center">Provincia</th>
                                                                    <th class="text-center">Distrito</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="derrames?.length > 0">
                                                                <tr *ngFor="let a of derrames">
                                                                    <td class="text-left">{{ a.lote }}</td>
                                                                    <td class="text-left">{{ a.descripcion }}</td>
                                                                    <td class="text-left">{{ a.fec_derrame }}</td>
                                                                    <td class="text-center">{{ a.agno }}</td>
                                                                    <td class="text-left">{{ a.region }}</td>
                                                                    <td class="text-left">{{ a.provincia }}</td>
                                                                    <td class="text-left">{{ a.distrito }}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="derrames?.length <= 0">
                                                                <tr>
                                                                    <td colspan="7">No se encontraron registros.</td>
                                                                </tr>
                                                            </tbody>

                                                            <div *ngIf="derrames.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
                                                                <div class="d-flex justify-content-left">
                                                                    <ngb-pagination [collectionSize]="derramesCollectionSize" [(page)]="derramesPage" [pageSize]="derramesPageSize" [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(2)">
                                                                        <ng-template ngbPaginationFirst>Primero</ng-template>
                                                                        <ng-template ngbPaginationLast>Último</ng-template>
                                                                        <ng-template ngbPaginationPrevious><</ng-template>
                                                                        <ng-template ngbPaginationNext>></ng-template>
                                                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                    </ngb-pagination>
                                                                </div>
                                                            </div>

                                                        </table>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#infraccioneslaborales">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo
                                            de cumplimiento de normas
                                        </button> -->

                                    </div>

                                    <!--Accidentes-->
                                    <div class="jpg-contentarticle tab-pane fade"
                                        [ngClass]="indicador == 4 ? 'active show' : ''" id="tab03">

                                        <div *ngIf="_usuarioService.isPremium() && accidentes?.length > 0">
                                            <div class="col-md-12 text-right">
                                                <br>
                                                <a href="javascript:void(0);" (click)="descargarResultados(4)"
                                                    class="btn btn-dark text-uppercase font-weight-bold px-5"> <i
                                                        class="fa fa-download"></i> Descargar</a>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                                                <div class="col-12 col-sm-12 col-md-12 ">
                                                    <div class="table-responsive">
                                                        <table *ngIf="accidentes"
                                                            class="table table-striped jpg-tablecategories tbl-nivel3">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Ubicación</th>
                                                                    <th class="text-center">Lote</th>
                                                                    <th class="text-center">Fecha de accidente</th>
                                                                    <th class="text-center">Año</th>
                                                                    <th class="text-center">Nivel de gravedad</th>
                                                                    <th class="text-center">Número de accidentados</th>
                                                                    <th class="text-center">Responsable del trabajador</th>
                                                                    <th class="text-center">Número de accidentes</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="accidentes?.length > 0">
                                                                <tr *ngFor="let a of accidentes">
                                                                    <td class="text-left">{{ a.region + ', ' + a.provincia +
                                                                        ', ' + a.distrito}}</td>
                                                                    <td class="text-left">{{ a.lote }}</td>
                                                                    <td class="text-left">{{ a.fec_accidente }}</td>
                                                                    <td class="text-center">{{ a.agno }}</td>
                                                                    <td class="text-left">{{ a.nivel_gravedad }}</td>
                                                                    <td class="text-left">{{ a.nro_accidentados }}</td>
                                                                    <td class="text-left">{{ a.responsable_trabajador }}
                                                                    </td>
                                                                    <td class="text-left">{{ a.nro_accidentes }}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="accidentes?.length <= 0">
                                                                <tr>
                                                                    <td colspan="16">No se encontraron registros.</td>
                                                                </tr>
                                                            </tbody>

                                                            <div *ngIf="accidentes.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
                                                                <div class="d-flex justify-content-left">
                                                                    <ngb-pagination [collectionSize]="accidentesCollectionSize" [(page)]="accidentesPage" [pageSize]="accidentesPageSize" [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(3)">
                                                                        <ng-template ngbPaginationFirst>Primero</ng-template>
                                                                        <ng-template ngbPaginationLast>Último</ng-template>
                                                                        <ng-template ngbPaginationPrevious><</ng-template>
                                                                        <ng-template ngbPaginationNext>></ng-template>
                                                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                    </ngb-pagination>
                                                                </div>
                                                            </div>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#multasperdonadas">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo
                                            de cumplimiento de normas
                                        </button> -->

                                    </div>

                                    <!--Permisos de agua-->
                                    <div class="jpg-contentarticle tab-pane fade"
                                        [ngClass]="indicador == 3 ? 'active show' : ''" id="tab04">

                                        <div *ngIf="_usuarioService.isPremium() && permisos?.length > 0">
                                            <div class="col-md-12 text-right">
                                                <br>
                                                <a href="javascript:void(0);" (click)="descargarResultados(3)"
                                                    class="btn btn-dark text-uppercase font-weight-bold px-5"> <i
                                                        class="fa fa-download"></i> Descargar</a>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="jpg-starthiringhold jpg-innerspace jpg-haslayout">
                                                <div class="col-12 col-sm-12 col-md-12 ">
                                                    <div class="table-responsive">
                                                        <table *ngIf="permisos"
                                                            class="table table-striped jpg-tablecategories tbl-nivel3">
                                                            <thead>
                                                                <tr>
                                                                    <th class="text-center">Tipo de uso</th>
                                                                    <th class="text-center">Volumen (m3)</th>
                                                                    <th class="text-center">Resolución</th>
                                                                    <th class="text-center">Fecha de resolución</th>
                                                                    <th class="text-center">Año</th>
                                                                    <th class="text-center">Clase derecho</th>
                                                                    <th class="text-center">Región</th>
                                                                    <th class="text-center">Provincia</th>
                                                                    <th class="text-center">Fuente</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody *ngIf="permisos?.length > 0">
                                                                <tr *ngFor="let m of permisos">
                                                                    <td class="text-left">{{ m.tipo_uso }}</td>
                                                                    <td class="text-center">{{ m.volumen_m3 }}</td>
                                                                    <td class="text-center">{{ m.resolucion }}</td>
                                                                    <td class="text-center text-primary">
                                                                        <!-- <a [href]="m.url_resolucion" target="_blank">{{ m.fec_resolucion }}</a> -->
                                                                        <a *ngIf="m.url_resolucion && m.url_resolucion != null"
                                                                            [href]="m.url_resolucion" target="_blank">{{
                                                                            m.fec_resolucion }}</a>
                                                                        <a *ngIf="!(m.url_resolucion && m.url_resolucion != null)"
                                                                            href="javascript:void(0)">{{ m.fec_resolucion
                                                                            }}</a>

                                                                    </td>
                                                                    <td class="text-center">{{ m.agno }}</td>
                                                                    <td class="text-center">{{ m.clase_derecho }}</td>
                                                                    <td class="text-center">{{ m.region }}</td>
                                                                    <td class="text-center">{{ m.provincia }}</td>
                                                                    <td class="text-center">{{ m.fuente }}</td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="permisos?.length <= 0">
                                                                <tr>
                                                                    <td colspan="16">No se encontraron registros.</td>
                                                                </tr>
                                                            </tbody>

                                                            <div *ngIf="permisos.length > 0 && !verCifras" class="col-sm-12 p-0 mt-4 mb-4">
                                                                <div class="d-flex justify-content-left">
                                                                    <ngb-pagination [collectionSize]="permisosCollectionSize" [(page)]="permisosPage" [pageSize]="permisosPageSize" [ellipses]="false" [maxSize]="5" [boundaryLinks]="true" [rotate]="true" (pageChange)="refreshData(4)">
                                                                        <ng-template ngbPaginationFirst>Primero</ng-template>
                                                                        <ng-template ngbPaginationLast>Último</ng-template>
                                                                        <ng-template ngbPaginationPrevious><</ng-template>
                                                                        <ng-template ngbPaginationNext>></ng-template>
                                                                        <ng-template ngbPaginationEllipsis>...</ng-template>
                                                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                    </ngb-pagination>
                                                                </div>
                                                            </div>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- <button class="btn btn-info-semaforo m-3" data-toggle="modal"
                                            data-target="#multasperdonadas">
                                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo
                                            de cumplimiento de normas
                                        </button> -->

                                    </div>

                                </div>

                                <!--Tabs infranctions End-->

                            </div>
                        </div>
                    </div>
                </div>
                <!--Company Details End-->

            </div>
        </div>

        <!-- Modal infracciones laborales -->
        <div class="modal fade" id="infraccioneslaborales" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <div class="btn-info-semaforo m-3">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo de cumplimiento
                            de normas
                        </div>

                        <div class="m-4">
                            <div class="d-flex align-self-center align-items-start mb-4">
                                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa poco infractora</strong><br />
                                        Según nuestra metodología, se consideró a una empresa poco infractora
                                        cuando...</small>
                                </div>
                            </div>
                            <div class="d-flex align-self-center align-items-start mb-4">
                                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa medianamente infractora</strong><br />
                                        Según nuestra metodología, se consideró a una empresa medianamente infractora
                                        cuando...</small>
                                </div>
                            </div>
                            <div class="d-flex align-self-center align-items-start mb-3">
                                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa muy infractora</strong><br />
                                        Según nuestra metodología, se consideró a una empresa muy infractora
                                        cuando...</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Modal infracciones ambientales -->
        <div class="modal fade" id="infraccionesambientales" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <div class="btn-info-semaforo m-3">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo de cumplimiento
                            de normas
                        </div>

                        <div class="m-4">
                            <div class="d-flex align-self-center align-items-start mb-4">
                                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa libre de infracciones</strong><br />
                                        Una empresa libre de infracciones es una empresa que...</small>
                                </div>
                            </div>
                            <div class="d-flex align-self-center align-items-start mb-4">
                                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa medianamente infractora</strong><br />
                                        Una empresa libre de infracciones es una empresa que... </small>
                                </div>
                            </div>
                            <div class="d-flex align-self-center align-items-start mb-3">
                                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa muy infractora</strong><br />
                                        Una empresa libre de infracciones es una empresa que... </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal multas perdonadas -->
        <div class="modal fade" id="multasperdonadas" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">

                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <div class="btn-info-semaforo m-3">
                            <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Semáforo de cumplimiento
                            de normas
                        </div>

                        <div class="m-4">
                            <div class="d-flex align-self-center align-items-start mb-4">
                                <img src="../../../../assets/img/green.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa libre de infracciones</strong><br />
                                        Una empresa libre de infracciones es una empresa que... lorem ipsum
                                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                                        lorem ipsum lorem ipsum.</small>
                                </div>
                            </div>
                            <div class="d-flex align-self-center align-items-start mb-4">
                                <img src="../../../../assets/img/yellow.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa medianamente infractora</strong><br />
                                        Una empresa libre de infracciones es una empresa que... lorem ipsum
                                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                                        lorem ipsum lorem ipsum.</small>
                                </div>
                            </div>
                            <div class="d-flex align-self-center align-items-start mb-3">
                                <img src="../../../../assets/img/red.png" alt="" class="icon icon-semaforo-1 mr-2 my-1">
                                <div class="mr-2">
                                    <small><strong>Empresa muy infractora</strong><br />
                                        Una empresa libre de infracciones es una empresa que... lorem ipsum
                                        lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                                        lorem ipsum lorem ipsum.</small>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- Semaforo de empresa End-->
    </main>
</div>
<!--Main End-->