import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  
  
  constructor() { }

  onlyNumbers(control: FormControl){
    const onlyNumbersPattern: RegExp = new RegExp('^[0-9]+$');
    const isOnlyNumbers = onlyNumbersPattern.test(control.value || '');
    const isValid = isOnlyNumbers;
    return isValid ? null : { 'onlyNumbers': true };
  }

}
