import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../services/util/alert.service';
import { UsuarioService } from '../../services/usuario.service';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.css'],
})
export class IngresoComponent implements OnInit {
  // REACTIVE FORMS
  formaLogin: FormGroup;
  flagFormaValida: boolean = true;
  mensajeError: string = '';

  constructor(
    private _authService: AuthService,
    private _alertService: AlertService,
    private _usuarioService: UsuarioService,
    public router: Router,
    private authService: SocialAuthService
  ) {}

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      this._usuarioService
        .registroGoogle({ token: data.authToken, idToken: data.idToken })
        .subscribe((res) => {
          if (res.success) {
            this._authService.token = res.token;
            this._authService.usuario = data.email;
            localStorage.setItem('token', res.token);
            localStorage.setItem('usuario', data.email);
            this._usuarioService.getUsuarioPerfil().subscribe((resp) => {
              if (resp.success) {
                this.router.navigate(['/industrias-extractivas']);
              }
            });
          }
        });
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.formaLogin = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required]),
      recordarCuenta: new FormControl(false),
    });

    // VER SI RECUERDAME ESTÁ ACTIVADO
    if (localStorage.getItem('usuario_login')) {
      this.formaLogin.controls['email'].setValue(
        localStorage.getItem('usuario_login')
      );
      this.formaLogin.controls['password'].setValue(
        localStorage.getItem('password_login')
      );
      this.formaLogin.controls['recordarCuenta'].setValue(true);
    }
  }

  validarLogin() {
    if (this.formaLogin.valid) {
      this.flagFormaValida = true;

      this._alertService.alertLoading('Verificando credenciales');

      // Llamando al servicio Login
      this._authService
        .login(
          this.formaLogin.controls['email'].value,
          this.formaLogin.controls['password'].value,
          this.formaLogin.controls['recordarCuenta'].value
        )
        .subscribe(
          (resp) => {
            if (resp.success) {
              // OBTENIENDO EL PERFIL DEL USUARIO
              this._usuarioService.getUsuarioPerfil().subscribe((resp) => {
                if (resp.success) {
                  if (resp.perfil.id_plan) {
                    this.router.navigate(['/industrias-extractivas']);
                  } else {
                    this.router.navigate(['/planes']);
                  }
                }
                this._alertService.closeAlert();
              });
            } else {
              this._alertService.alertError('Error', 'Error al ingresar');
            }
          },
          (error) => {
            // this._modalService.modalError('Credenciales inválidas','Verifique e intente de nuevo');
            this.flagFormaValida = false;

            if (error.error.code === 'ACCOUNT_NOT_ACTIVATED') {
              this.mensajeError =
                'Cuenta no verificada. Por favor revise su correo.';
            }
            if (error.error.code === 'ACCOUNT_NOT_ACTIVATED_BY_CONVOCA') {
              this.mensajeError =
                'Cuenta no verificada por Convoca. Por favor revise su correo.';
            }
            if (
              error.error.code === 'PASSWORD_DOESNT_MATCH' ||
              error.error.code === 'USER_NOT_FOUND' ||
              error.error.code === 'BAD_REQUEST'
            ) {
              this.mensajeError = 'Las credenciales ingresadas son incorrectas';
            }

            // this._alertService.alertError('Error',this.mensajeError);
            this._alertService.closeAlert();
          }
        );
    } else {
      // console.log('form-invalido');
      this.flagFormaValida = false;
      this.mensajeError = 'Datos inválidos';
    }
  }
}
