// ng-router to generate 
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages.component';

import { HomeComponent } from './home/home.component';
import { RegistroComponent } from './registro/registro.component';
import { IngresoComponent } from './ingreso/ingreso.component';

import { IndustriasExtractivasComponent } from './search/industrias-extractivas/industrias-extractivas.component';
import { DetalleEmpresaComponent } from './search/detalle-empresa/detalle-empresa.component';
import { DetalleSemaforoComponent } from './search/detalle-semaforo/detalle-semaforo.component';
import { BusquedaMasivaComponent } from './search/busqueda-masiva/busqueda-masiva.component';
import { BusquedaMasivaDatosEncontradosComponent } from './search/busqueda-masiva-datos-encontrados/busqueda-masiva-datos-encontrados.component';
import { ConfirmarCuentaComponent } from './confirmar-cuenta/confirmar-cuenta.component';
import { CuentaComponent } from './cuenta/cuenta.component';
import { PlanesComponent } from './planes/planes.component';
import { PagoResultComponent } from './pagos/pago-result/pago-result.component';
import { DetalleAdicionalComponent } from './search/detalle-adicional/detalle-adicional.component';
import { OnlyDesktopComponent } from './only-desktop/only-desktop.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NosotrosComponent } from './nosotros/nosotros.component';
import { ReportajesComponent } from './reportajes/reportajes.component';
import { MetodologiasComponent } from './metodologias/metodologias.component';

const routes: Routes = [
    { 
        path: '',
        component: PagesComponent,
        children: [
        //   { path: '', component: HomeComponent },
          { path: '', component: IndustriasExtractivasComponent },
          { path: 'home', component: HomeComponent },
          { path: 'registro', component: RegistroComponent },
          { path: 'ingreso', component: IngresoComponent },
          { path: 'password', component: ForgotPasswordComponent },
          { path: 'recuperar-password', component: ResetPasswordComponent },
          { path: 'confirmar-cuenta', component: ConfirmarCuentaComponent },
          { path: 'cuenta', component: CuentaComponent },
          { path: 'only-desktop', component: OnlyDesktopComponent },
          { path: 'planes', component: PlanesComponent },
          { path: 'nosotros', component: NosotrosComponent },
          { path: 'reportajes', component: ReportajesComponent },
          { path: 'metodologia', component: MetodologiasComponent },
          { path: 'pago/result', component: PagoResultComponent },
          { path: 'industrias-extractivas', component: IndustriasExtractivasComponent },
          { path: 'detalle-empresa', component: DetalleEmpresaComponent },
          { path: 'detalle-semaforo', component: DetalleSemaforoComponent },
          { path: 'detalle-adicional', component: DetalleAdicionalComponent },
          { path: 'busqueda-masiva', component: BusquedaMasivaComponent },
          { path: 'busqueda-masiva-datos', component: BusquedaMasivaDatosEncontradosComponent },
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule {}
