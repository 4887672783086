import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UsuarioService } from '../../services/usuario.service';
import { AlertService } from '../../services/util/alert.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  formaEmail: FormGroup;
  emailVerificacion: string;
  flagFormaValida: boolean = true;
  mensajeError: string = '';

  constructor( public _usuarioService : UsuarioService,
               public _alertService: AlertService,
               public _router: Router ) { }

  ngOnInit(): void {
    this.formaEmail = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    })
  }

  confirmarRecuperacion(){

    if( this.formaEmail.valid ){

      this._alertService.alertLoading("Enviando email...");
      
      this.emailVerificacion = this.formaEmail.controls['email'].value;
      this._usuarioService.recuperarPassword(this.emailVerificacion).subscribe(
        resp => {
          if(resp.success){
            this.flagFormaValida = true;
            this._alertService.alertSuccessHtml('Email enviado',`Enviamos un email a <b>${this.emailVerificacion}</b> con los pasos a seguir para recuperar tu contraseña.`).then(
              result => {
                if(result.value){
                  this._router.navigate(['/home']);
                }
              }
            );
          }
        },
        error => {
          this._alertService.alertError('Ups','Ocurrió un error. Vuelve a intentar en unos minutos.');
        }
      );
    }else{
      this.flagFormaValida = false;
      this.mensajeError = 'Debe ingresar un email válido';
    }
  }

}
