import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { windowWhen } from 'rxjs/operators';

import { UsuarioService } from 'src/app/services/usuario.service';

import { Usuario } from '../../models/usuario.model';
import { AuthService } from '../../services/auth.service';
import { DataReferenciaService } from '../../services/data-referencia.service';
import { AlertService } from '../../services/util/alert.service';
import { FormValidatorService } from '../../services/util/form-validator.service';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
})
export class RegistroComponent implements OnInit {
  usuario: Usuario = new Usuario();

  // REACTIVE FORMS
  formaRegistro: FormGroup;
  flagFormaValida: boolean = true;
  flagUsuarioCreado: boolean = false;
  flagVerFormulario: boolean = false;

  countries: any;

  constructor(
    private _usuarioService: UsuarioService,
    private _authService: AuthService,
    public _customValidators: FormValidatorService,
    private _alertService: AlertService,
    private _dataReferencia: DataReferenciaService,
    public router: Router,
    private authService: SocialAuthService
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);

    this._alertService.alertLoading('Cargando formulario...');

    this.usuario.apellidos = 'Apellido';
    this.usuario.sexo = 'M';
    this.usuario.profesion = 'Profesion';

    // REACTIVE FORMS FORMAS
    this.formaRegistro = new FormGroup({
      nombres: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      // apellidos: new FormControl(null, [Validators.required]),
      // sexo: new FormControl(null, [Validators.required]),
      // profesion: new FormControl(null, [Validators.required]),
      pais: new FormControl(null, [Validators.required]),
      telefono: new FormControl(null, [
        Validators.required,
        Validators.maxLength(13),
        Validators.minLength(6),
        this._customValidators.onlyNumbers,
      ]),
      organizacion: new FormControl(null),
      // password: new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(64)]),
      password: new FormControl(null, [Validators.required]),
      password2: new FormControl(null, [Validators.required]),
      aceptaTerminos: new FormControl(null, [Validators.required]),
      recibirNoticias: new FormControl(null, [Validators.required]),
      recaptchaReactive: new FormControl(null, Validators.required),
    });

    this.getPaises();
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data) => {
      this._usuarioService
        .registroGoogle({ token: data.authToken, idToken: data.idToken })
        .subscribe((res) => {
          if (res.success) {
            this._authService.token = res.token;
            this._authService.usuario = data.email;
            localStorage.setItem('token', res.token);
            localStorage.setItem('usuario', data.email);
            this._usuarioService.registeredFromGoogle = true;
            this._usuarioService.getUsuarioPerfil().subscribe((resp) => {
              if (resp.success) {
                this.router.navigate(['/industrias-extractivas']);
              }
            });
          }
        });
    });
  }

  registrarUsuario() {
    if (this.formaRegistro.valid) {
      this.flagFormaValida = true;

      this.getInfoForm();

      this._usuarioService.registrarUsuario(this.usuario).subscribe(
        (resp) => {
          // this.confirmarCorreo();
          this.flagUsuarioCreado = true;
          this.limpiarForm();
          setTimeout(() => {
            this.router.navigate(['/home']);
          }, 5000);
        },
        (error) => {
          //this.flagFormaValida = false;
          this.flagUsuarioCreado = false;
        }
      );

      // this.router.navigate(["/ingreso"]);
    } else {
      // this._modalService.modalError('Credenciales inválidas','Verifique e intente de nuevo');
      this.flagFormaValida = false;
    }
  }

  getPaises() {
    this._dataReferencia.getPaises().subscribe((resp) => {
      if (resp?.success) {
        this.countries = resp.paises;
        this._alertService.closeAlert();
      }
    });
  }

  // confirmarCorreo(){
  //   this._usuarioService.confirmarCorreo(this._authService.token).subscribe(
  //     resp=>{
  //       this.flagUsuarioCreado = true;
  //     }
  //   );
  // }

  getInfoForm() {
    this.usuario.email = this.formaRegistro.controls['email'].value;
    this.usuario.nombres = this.formaRegistro.controls['nombres'].value;
    // this.usuario.apellidos = this.formaRegistro.controls['apellidos'].value;
    this.usuario.celular = this.formaRegistro.controls['telefono'].value;
    this.usuario.pais = this.formaRegistro.controls['pais'].value;
    // this.usuario.profesion = this.formaRegistro.controls['profesion'].value;
    this.usuario.organizacion =
      this.formaRegistro.controls['organizacion'].value;
    this.usuario.password = this.formaRegistro.controls['password'].value;
    this.usuario.recaptcha =
      this.formaRegistro.controls['recaptchaReactive'].value;
  }

  limpiarForm() {
    this.formaRegistro.controls['email'].setValue(null);
    this.formaRegistro.controls['nombres'].setValue(null);
    this.formaRegistro.controls['telefono'].setValue(null);
    this.formaRegistro.controls['pais'].setValue(null);
    this.formaRegistro.controls['organizacion'].setValue(null);
    this.formaRegistro.controls['password'].setValue(null);
    this.formaRegistro.controls['password2'].setValue(null);
    this.formaRegistro.controls['recaptchaReactive'].setValue(null);
  }

  verFormulario(value: boolean) {
    this.flagVerFormulario = value;
  }
}
