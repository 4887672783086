import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalCaptchaComponent } from '../../components/modal-captcha/modal-captcha.component';
import { DataEmpresaService } from '../data-empresa.service';

@Injectable({
  providedIn: 'root'
})
export class CaptchaMessageService {

  countCaptchaModal: number = 0;

  _modalOptionsNormal: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'md',
    centered: true
  };

  constructor( public dataEmpresaService: DataEmpresaService  ) {
  }

  openModalCaptcha(){

    if(this.countCaptchaModal === 0){
      this.countCaptchaModal++
      return true;
    }else{
      return false;
    }

  }
}
