import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AlertService } from './util/alert.service';
import { Router } from '@angular/router';

const base_url = environment.base_url + '/membresia';
@Injectable({
  providedIn: 'root'
})
export class MembresiaService {

  constructor(private http: HttpClient,
              private authService: AuthService,
              private _alertService: AlertService,
              private router: Router
              ) { }

  getMembresias() {

    return this.http.get(`${base_url}/listar-publico`).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
            //return throwError(error);
          }
        }
      )
    );
  }

  getMembresiasForUser() {

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.token
    });

    return this.http.get(`${base_url}/listar`, { headers: headers }).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
            //return throwError(error);
          }
        }
      )
    );
  }

  adquirirFree(id:number){

    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.authService.token
    });

    return this.http.post(`${base_url}/adquirir-free/${id}`,null, { headers: headers }).pipe(
      tap(
        (resp: any) => {
          return resp;
        },
        error => {
          if (error instanceof HttpErrorResponse) {
            this.manejarError(error);
            //return throwError(error);
          }
        }
      )
    );
  }

  manejarError( error: HttpErrorResponse, urlRedirect: string = '' ){

    // console.log('Error',error);

    let titulo = '';
    let subtitulo = '';

    switch (error.status) {
      
      case 401:

        // if(this.authService.token){
        if(this.authService.getToken()){
          titulo = 'Su sesión ha expirado';
          subtitulo = 'Por favor vuelva a ingresar';
        }else{
          titulo = 'Disponible solo para usuarios registrados';
          subtitulo = 'Por favor ingrese con sus credenciales';
        }
        
        this._alertService.alertError(titulo, subtitulo,'Ingresar').then(
          resp=> {
            // console.log('resp-alert',resp);
            this.authService.logout();
            this.router.navigate(["/ingreso"]);
          }
        );
        break;
      case 404:
        
        if(error.error.code === 'RUC_NOT_FOUND'){
          titulo = 'El RUC no fue encontrado'
          subtitulo = 'Intente buscar en nuestro listado de empresas';
        }else{
          titulo = 'Ups'
          subtitulo = 'Ocurrió un problema. Vuelve a intentar.';
        }

        this._alertService.alertError(titulo, subtitulo,'Volver a lista de empresas').then(
          resp => {
            if(urlRedirect){
              this.router.navigate([`/${urlRedirect}`]);
            }
          }
        );

        break;
      case 400:
        
        if(error.error.code === 'USER_ALREADY_HAS_MEMBERSHIP'){
          titulo = 'Usted ya se ha suscrito anteriormente a un plan'
          subtitulo = '';
        }else{
          titulo = 'Ups'
          subtitulo = 'Ocurrió un problema. Vuelve a intentar.';
        }

        this._alertService.alertError(titulo, subtitulo,'Aceptar').then(
          resp => {
            if(urlRedirect){
              this.router.navigate([`/${urlRedirect}`]);
            }
          }
        );
        
        break;
      default:

        this._alertService.alertError('Ups','Ocurrió un problema. Vuelve a intentar.').then(
          resp => {
            if(urlRedirect){
              this.router.navigate([`/${urlRedirect}`]);
            }
          }
        );

        break;
    }

    return throwError(error);
  }



}
