<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
  <!-- Formulario de ingreso Start-->
  <div class="jpg-haslayout">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-xs-12 col-sm-12 col-md-10 push-md-1 col-lg-8 push-lg-2">
          <!-- <div class="">
            <button class="btn btn-social-icon google w-75" (click)="signInWithGoogle()"><span
                class="fa fa-google"></span>
              Regístrate con Google
            </button>
            <br>
            <div class="opcional">
              <div class="barra"></div>
              <div class="texto">Ó</div>
              <div class="barra"></div>
            </div>
          </div> -->

          <div *ngIf="!flagVerFormulario" class="row card p-4">
            <div class="row p-3">
              <div class="col-12 text-center pt-2">
                <h3>Ingresa con tu correo Gmail</h3>
                <br />
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-danger btn-red w-50"
                    (click)="signInWithGoogle()"
                  >
                    <span class="fa fa-google"></span>
                    Ingresa con Gmail
                  </button>
                </div>
              </div>
            </div>
            <div class="row p-3">
              <div class="col-12 text-center pt-2">
                <h3>O ingresa con tus datos</h3>
                <br />
                <div class="form-group">
                  <button
                    type="submit"
                    class="btn btn-primary btn-blue w-50"
                    (click)="verFormulario(true)"
                  >
                    Formulario
                  </button>
                </div>
              </div>
            </div>
            <div class="jpg-registerformfooter">
              <span
                >¿Ya tienes una cuenta?
                <a [routerLink]="['/ingreso']">Inicia sesión</a></span
              >
            </div>
          </div>

          <div *ngIf="flagVerFormulario" class="row card p-4">
            <div class="col-12 text-left pt-2">
              <button
                style="background: none; color: #949494"
                (click)="verFormulario(false)"
              >
                <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
              </button>
            </div>
            <div class="col-12 text-center pt-2">
              <h3>Regístrate usando tu correo electrónico</h3>
            </div>
            <div class="col-12 pt-2">
              <form
                [formGroup]="formaRegistro"
                (ngSubmit)="registrarUsuario()"
                class="wt-formtheme wt-formregister"
              >
                <div class="form-group">
                  <label for="name" class="cols-sm-2 control-label"
                    >Nombre</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="nombres"
                      name="nombres"
                      id="nombres"
                      placeholder="Ingresar nombre"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" class="cols-sm-2 control-label"
                    >País</label
                  >
                  <div class="cols-sm-10">
                    <select
                      id="cboCountries"
                      class="form-control"
                      formControlName="pais"
                      name="cboCountries"
                      required
                    >
                      <option
                        value="null"
                        disabled="true"
                        hidden
                        [selected]="true"
                      >
                        Seleccione...
                      </option>
                      <option
                        *ngFor="let country of countries"
                        [ngValue]="country.codigo"
                      >
                        {{ country.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group">
                  <label for="telefono" class="cols-sm-2 control-label"
                    >Teléfono</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="telefono"
                      name="telefono"
                      id="telefono"
                      placeholder="Ingresar teléfono"
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label for="email" class="cols-sm-2 control-label"
                    >Email</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="email"
                      name="email"
                      id="email"
                      placeholder="Ingresar email"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <label for="username" class="cols-sm-2 control-label"
                    >Organizaci&oacute;n</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="organizacion"
                      name="organizacion"
                      id="organizacion"
                      placeholder="Ingresar nombre de la oganización"
                    />
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="row">
                    <div class="col-sm-12 col-md-6 mt-3">
                      <label for="password" class="cols-sm-2 control-label"
                        >Contraseña</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        formControlName="password"
                        name="password"
                        id="password"
                        placeholder="Ingresar contraseña"
                      />
                    </div>
                    <div class="col-sm-12 col-md-6 mt-3">
                      <label for="password2" class="cols-sm-2 control-label"
                        >Confirmar contraseña</label
                      >
                      <input
                        type="password"
                        class="form-control"
                        formControlName="password2"
                        name="password2"
                        id="password2"
                        placeholder="Confirmar contraseña"
                      />
                    </div>
                  </div>
                </div>

                <div class="jpg-checkboxholder">
                  <span class="jpg-checkbox">
                    <input
                      id="aceptaTerminos"
                      type="checkbox"
                      formControlName="aceptaTerminos"
                      name="aceptaTerminos"
                      value="aceptaTerminos"
                      checked=""
                    />
                    <label for="aceptaTerminos">
                      <span
                        >Acepto los
                        <a
                          href="https://convoca.pe/deepdata/terminosycondicionesdeuso"
                          target="_blank"
                          >términos y condiciones de uso</a
                        >.</span
                      ></label
                    >
                  </span>
                  <span class="jpg-checkbox">
                    <input
                      id="recibirNoticias"
                      type="checkbox"
                      formControlName="recibirNoticias"
                      name="recibirNoticias"
                      value="recibirNoticias"
                      checked
                    />
                    <label for="recibirNoticias"
                      ><span
                        >Me gustaría recibir noticias sobre Convoca Deep Data y
                        otras plataformas de Convoca.</span
                      ></label
                    >
                  </span>
                </div>

                <div class="form-row" *ngIf="!flagFormaValida">
                  <div class="form-group col-12">
                    <span class="text-danger"
                      >Debe de llenar todos los campos</span
                    >
                  </div>
                </div>

                <br />

                <div class="form-group col-12 pl-0">
                  <div style="transform: scale(0.8); transform-origin: 0">
                    <re-captcha
                      formControlName="recaptchaReactive"
                      name="recaptchaReactive"
                    ></re-captcha>
                    <!-- <div class="g-recaptcha" data-sitekey="6LcDEMoZAAAAAL1zYADrCQpJ0MBJ9GR115ElDjvr"></div> -->
                  </div>
                </div>

                <div class="form-group">
                  <button type="submit" class="btn jpg-btn">
                    Registra tu cuenta
                  </button>
                </div>
              </form>

              <div
                *ngIf="flagUsuarioCreado"
                class="d-flex col-sm-12 justify-content-center"
              >
                <ngb-alert type="success" class="text-center"
                  >Usuario registrado con éxito. Se le envió un correo de
                  verificación a <b>{{ usuario.email }}</b></ngb-alert
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Formulario de ingreso End-->
</main>
