<!--Main Start-->
<main id="jpg-main" class="jpg-main jpg-haslayout jpg-innerbgcolor">
  <!-- Formulario de ingreso Start-->
  <div class="jpg-haslayout">
    <br /><br /><br />
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-sm-12 col-md-10 col-lg-6">
          <div class="row card p-4">
            <div class="col-12 text-center pt-2">
              <h3>Recuperar contraseña</h3>
            </div>
            <div class="col-12 pt-2">
              <form
                [formGroup]="formaEmail"
                (ngSubmit)="confirmarRecuperacion()"
                class="wt-formtheme wt-formregister"
              >
                <div class="form-group">
                  <label for="email" class="cols-sm-2 control-label"
                    >Ingresa el email asociado a tu usuario</label
                  >
                  <div class="cols-sm-10">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="email"
                      name="email"
                      id="email"
                      placeholder="Ingresar Email"
                      required
                    />
                  </div>
                </div>
                <div class="form-group" *ngIf="!flagFormaValida">
                  <span class="text-danger">{{ mensajeError }}</span>
                </div>
                <div class="form-group">
                  <button type="submit" class="btn jpg-btn">
                    Recuperar contraseña
                  </button>
                </div>
              </form>
            </div>
            <div class="jpg-registerformfooter">
              <!-- <span><a [routerLink]="['/password']">Olvidé mi contraseña</a></span> -->
              <span><a [routerLink]="['/ingreso']">Volver al ingreso</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
  </div>
  <!-- Formulario de ingreso End-->
</main>
<!--Main End-->
