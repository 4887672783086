import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HttpClientModule } from '@angular/common/http';
// import { NgxEchartsModule } from 'ngx-echarts';
// import * as echarts from 'echarts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { TableSorteableComponent, NgbdSortableHeader } from './table-sorteable/table-sorteable.component';
import { ModalCaptchaComponent } from './modal-captcha/modal-captcha.component';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';


@NgModule({
  declarations: [
    TableSorteableComponent,
    NgbdSortableHeader,
    ModalCaptchaComponent,
  ],
  exports: [
    ModalCaptchaComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule,
  ],
  providers: [{
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey: '6LcDEMoZAAAAAL1zYADrCQpJ0MBJ9GR115ElDjvr',
    } as RecaptchaSettings,
  }],
  bootstrap: [TableSorteableComponent]
})
export class ComponentsModule { }
